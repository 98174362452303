import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PinIcon from '@mui/icons-material/Pin';
import { useRecoilState } from 'recoil';
import { pinnedState } from '../state/MetaNavigationState';
import { useTheme } from '@mui/material/styles';
import {Link} from "react-router-dom";
import Logo from "../../../Logo/Logo";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";
import AccountMenu from "../AccountMenu/AccountMenu";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
const CustomAppBar = () => {
    const [pinned, setPinned] = useRecoilState(pinnedState);
    const [preview, setPreview] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const theme = useTheme();

    const handleMouseEnter = () => {
        if (!pinned) {
            setPreview(true);
        }
        setShowPin(true);
    };

    const handleMouseLeave = () => {
        if (!pinned) {
            setPreview(false);
        }
        setShowPin(false);
    };

    const handleClick = () => {
        if (!pinned) {
            setPreview(false);
            setPinned(true);
        }
    };

    const togglePinned = () => {
        setPreview(false);
        setPinned(!pinned);
    };

    const appBarHeight = pinned ? 'auto' : preview ? '20px' : '5px';

    return (
        <>
            {!pinned && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        height: appBarHeight,
                        backgroundColor: theme.palette.primary.light,
                        zIndex: 1000,
                        transition: 'height 0.5s',
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {preview && (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleClick}
                            >
                                <ArrowDropDownIcon />
                            </IconButton>
                        </div>
                    )}
                </div>
            )}
            {pinned && (
                <>
                    <AppBar
                        position="static"
                        style={{ height: '50px' }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <Toolbar>
                            {showPin ? (
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="pin"
                                    onClick={togglePinned}
                                    style={{
                                        marginLeft: '5px',
                                    }}
                                >
                                    <ArrowCircleUpIcon/>
                                </IconButton>
                            ) : (
                                // Placeholder with the same width as the pin icon
                                <div
                                    style={{
                                        width: '40px', // Width of IconButton
                                        marginLeft: '5px',
                                    }}
                                ></div>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flex: 1,
                                    marginLeft: '10px',
                                }}
                            >
                                <Link to={"/"}>
                                    <Logo height={30}/>
                                </Link>
                                <div><ThemeSwitch/></div>
                                <AccountMenu/>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            height: '50px', // Default AppBar height for desktop
                        }}
                    ></div>
                </>
            )}
        </>
    );
};

export default CustomAppBar;

/*
    <AppBar position="fixed" color="primary" style={{height: displayMetaNavigation ? 40 : 5}}
            sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <Container maxWidth="xl">
            <Toolbar variant="dense" color="primary"
                     sx={{justifyContent: "space-between", color: "primary !important"}}>
                <Link to={"/"}>
                    <Logo height={30}/>
                </Link>
                <div><ThemeSwitch/></div>
                <AccountMenu/>
            </Toolbar>
        </Container>
    </AppBar>
    <Toolbar/>
*/
