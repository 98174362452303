import * as React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    amountRawDataSelector
} from "../../../../../dataProvider/trend-analytics/zoomAndFilter/search/facetation/facetation";
import {
    activeFilterSetIdAtom,
    amountFilteredDataSelector,
    filterSetsAtom,
    filterSetsForSubNavigationSelector
} from "../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";
import {
    checkTotalAmount,
    isStillLoadingDataSelector
} from "../../../../../dataProvider/trend-analytics/zoomAndFilter/search/lazy-loading/lazy-loading";
import YearSliderFilters from "./FilterSets/Filters/YearSliderFilters/YearSliderFilters";
import FacetFilters from "./FilterSets/Filters/FacetFilters/FacetFilters";
import PoiFilters from "./FilterSets/Filters/PoiFilters/PoiFilters";
import IconButton from "@mui/material/IconButton";
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {v4 as uuid4} from 'uuid';
import {recursiveDeepClone} from "../../../../../utility/utility";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import AmountLoadingInfos from "./FilterSets/AmountLoadingInfos/AmountLoadingInfos";
import CircularSpinner from "../../../../UI/LoadingSpinner/CircularSpinner";

function SubNavigation() {
    const [activeFilterSetId, setActiveFilterSetId] = useRecoilState(activeFilterSetIdAtom);
    const [filterSets, setFilterSets] = useRecoilState(filterSetsAtom);
    // const [facetFilters, toggleFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    // stores the stuff we display here.
    const formattedFilterSets = useRecoilValue(filterSetsForSubNavigationSelector);
    // const [useLocalAsGlobalSearchData, setUseLocalAsGlobalSearchData] = useRecoilState(useLocalAsGlobalSearchDataAtom);


    // add a new filter set with a new unique id.
    const addNewFilterSet = () => {
        // console.log('add filter set')
        // check if there is a filter set that is empty. If we already have an empty filter set we switch to it.
        let useExistingFilterSet = false;
        formattedFilterSets.forEach((d) => {
            if (d.facetFilters.length <= 0 && d.isSliderActive === false && d.poiFilters.length <= 0) {
                useExistingFilterSet = true;
                setActiveFilterSetId(d.filterSetId);
            }
            //  console.log(d)
        })
        // if the existing filter sets are not empty we create a new filter set.
        if (!useExistingFilterSet) {
            const newUUID = uuid4();
            // console.log(filterSets);
            setFilterSets((item) => [
                ...item,
                newUUID
            ]);
            setActiveFilterSetId(newUUID)
            // resetSliderValues();
        }
    }

    const removeFilterSetById = (idToRemove: any) => {
        const clone = recursiveDeepClone(filterSets);
        const filtered = clone.filter((d: any) => d !== idToRemove);
        // set the active filter set to the last one in the list.
        if (filtered.length >= 1) {
            setActiveFilterSetId(filtered[filtered.length - 1])
        } else {
            const newUUID = uuid4();
            filtered.push(newUUID)
            setActiveFilterSetId(newUUID)
        }
        setFilterSets(filtered);
    }

    const changeActiveFilterSet = (id: string | ((currVal: string) => string)) => {
        setActiveFilterSetId(id);
    }

    /*

function SubNavigation() {
    return (
        <AppBar position="fixed" color="default" style={{height: 30, marginTop: 121}} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2}}>
            <Toolbar variant="dense" sx={{justifyContent: "space-between"}}>
                <Grid container sx={{alignItems: "center", justify: "center", justifyContent: "center"}}
                      columns={{xs: 12, sm: 12, md: 12}}>
                    <Grid item xs={12}>
                        <FilterSets></FilterSets>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>

    )
}

export default SubNavigation
     */


    return (
        <AppBar position="fixed" color="default" style={{height: 30, marginTop: 121}}
                sx={{zIndex: (theme) => theme.zIndex.drawer + 2}}>
            <Toolbar variant="dense" sx={{justifyContent: "space-between"}}>
                <Grid container columns={{xs: 12, sm: 12, md: 12}}>

                    <React.Suspense
                        fallback={<CircularSpinner/>
                        }>
                        <AmountLoadingInfos/>
                    </React.Suspense>



                        <span style={{marginLeft: 5}}>Filters:</span>
                        {
                            formattedFilterSets.map((filter, index) => {
                                console.log(filter)
                                // eslint-disable-next-line no-mixed-operators
                                const activeFilterSetWrapper = filter.filterSetId === activeFilterSetId;
                                return (
                                    <div
                                        key={filter.filterSetId + '-wrapper'}
                                        style={{
                                            marginLeft: 5,
                                            paddingLeft: 5,
                                            marginRight: 5,
                                            display: 'flex'
                                        }}>
                                        <div
                                            key={filter.filterSetId + 'activeFilterSetWrapper-wrapper'}
                                            className={activeFilterSetWrapper ? 'activeFilterSetWrapper' : 'inActiveFilterSetWrapper'}
                                            onClick={() => {
                                                changeActiveFilterSet(filter.filterSetId)
                                            }}>
                                            {
                                                filter.isSliderActive && filter.manualSliderManipulation ?
                                                    <YearSliderFilters filter={filter}
                                                                       filterSetId={filter.filterSetId}/>
                                                    : null
                                            }
                                            {
                                                filter.facetFilters.map((d: any, j: any) => {
                                                    return (
                                                        <FacetFilters d={d} j={j} filterSetId={filter.filterSetId}/>
                                                    )
                                                })
                                            }
                                            {
                                                filter.poiFilters.map((d: any, j: any) => {
                                                    return (
                                                        <PoiFilters d={d} j={j} filterSetId={filter.filterSetId}/>
                                                    )
                                                })
                                            }
                                            {
                                                //  useLocalAsGlobalSearchData ? <DataLinkingFilters/> : null
                                            }
                                        </div>

                                        {
                                            ((filter.facetFilters.length > 0) || (filter.poiFilters.length > 0) || (filter.isSliderActive)) ?
                                                <IconButton onClick={() => {
                                                    removeFilterSetById(filter.filterSetId);
                                                }}>
                                                    <DeleteForeverIcon/>
                                                </IconButton>
                                                :
                                                null
                                        }


                                        {(index <= formattedFilterSets.length) ?
                                            <span style={{marginLeft: 4, marginRight: 4}}>{' '} or {' '}</span> : null}
                                    </div>
                                )
                            })
                        }
                        <span>
                <IconButton onClick={() => {
                    addNewFilterSet()
                }}>
                    <AddCircleOutline/>
                </IconButton>
            </span>

                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default SubNavigation;




