import {Link, To} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import LaunchIcon from "@mui/icons-material/Launch";
import * as React from "react";

function CustomCardGridItem(props: { cardTitle: string; routeLink: To; roles: any[]; icon: any }){
    return (
        <Grid item xs={3}>
            <Card>
                <CardContent style={{textAlign: "center"}}>
                    {props.icon}
                </CardContent>
                <CardActions>
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                            <Link to={props.routeLink} style={{textDecoration: "none"}}>
                                <Typography gutterBottom variant="h5" component="div" color="text.primary">
                                    {props.cardTitle}
                                </Typography>
                            </Link>
                        </Grid>
                        <Link to={props.routeLink} style={{textDecoration: "none"}} >
                            <Grid item color="text.primary">
                                <LaunchIcon  sx={{fontSize: "1.5em", pb: 0.2, pl: 1}}/>
                            </Grid>
                        </Link>
                    </Grid>
                </CardActions>
            </Card>
        </Grid>
    )
} export default CustomCardGridItem;