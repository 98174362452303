import Keycloak from "keycloak-js";

// Setup Keycloak client as needed
// Pass initialization options as required
const keycloak = new Keycloak({
    url:  "https://auth.morpheus.fbmd.h-da.de/auth",
    realm: "vissights",
    // clientId: process.env.NODE_ENV !== 'production' ? "react-front-end-development" : "react-front-end-production",
    clientId: process.env.NODE_ENV !== 'production' ? "react-front-end-development" : "semavis-demo",
});


export default keycloak;