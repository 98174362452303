import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {useRecoilValue} from "recoil";
import {fetchAggregatedMakroLevelDataSelectorFamily} from "../../../../../../dataProvider/trend-analytics/trend/trend";
import {recursiveDeepClone} from "../../../../../../utility/utility";
import {useEffect, useRef, useState} from "react";
import StaticDashboard from "../../../../../Dashboards/StaticDashboard/StaticDashboard";


function TrendDashboard() {
    const chartData = useRecoilValue(fetchAggregatedMakroLevelDataSelectorFamily("springer"));
    const newData = recursiveDeepClone(chartData).slice(0, 20);
    const [width, setWidth] = useState(null);
    const containerRef = useRef(null);
    const amountRows = 4;

    useEffect(() => {
        const handleResize = () => {
            // @ts-ignore
            setWidth(containerRef.current.getBoundingClientRect().width);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    // @ts-ignore
    return (
        <div ref={containerRef}>
            <StaticDashboard></StaticDashboard>
        </div>

    )
}

export default TrendDashboard;

/*
{
                    newData.map((d: any, i: number) => {
                        console.log(d);
                        return (
                            <Grid item xs={3} key={d + '-' + i}>
                                <Paper elevation={3} sx={{height: 140}}>
                                    <Typography><b>{d.name}</b></Typography>

*/
/*
<Sparkline width={(width / amountRows)-30} height={90} color={"black"}
           data={d?.forecast?.data?.timeseries}></Sparkline>

<LinearProgress
    variant="determinate"
    value={d?.forecast?.evaluation?.hybrid?.MAPE * 10}
    style={{ height: "10px", width: "40%"}}
/>

</Paper>
</Grid>
)
})
}
 */