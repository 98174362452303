import Container from "@mui/material/Container";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Step, StepLabel, Stepper} from "@mui/material";
import AiAssistant from "../AiAssistant/AiAssistant";


function CustomStepper() {
    const steps = ["Preparation", "Identification", "Screening"]
    return (
        <Container maxWidth="xl">
            <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                <Grid xs={2}>
                </Grid>
                <Grid xs={8} >
                    <Stepper activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
                <Grid xs={2}>
                    <AiAssistant></AiAssistant>
                </Grid>
            </Grid>
        </Container>
    )
}
export default CustomStepper


