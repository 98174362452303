// The initialization of the advanced search fields.
import {atom} from "recoil";

export const includeSelectionAtom = atom({
    key: "includeSelectionAtom-Key",
    default: [
        // {name: 'abstract', value: false},
        {name: 'affiliation', value: true},
        {name: 'author', value: true},
        //{name: 'contentType', value: false},
        //{name: 'copyright', value: false},
        {name: 'country', value: true},
        //{name: 'doc_dir', value: false},
        //{name: 'doi', value: false},
        //{name: 'ee', value: false},
        //{name: 'endingPage', value: false},
        //{name: 'genre', value: false},
        //{name: 'identifier', value: false},
        //{name: 'journalId', value: false},
        //{name: 'key', value: true},
        //{name: 'language', value: false},
        //{name: 'number', value: false},
        //{name: 'openAccess', value: false},
        //{name: 'publicationDate', value: false},
        //{name: 'publicationName', value: false},
        //{name: 'publisher', value: false},
        //{name: 'startingPage', value: false},
        {name: 'title', value: true},
        {name: 'topics', value: true},
        {name: 'type', value: true},
        //{name: 'url', value: false},
        //{name: 'volume', value: false},
        {name: 'year', value: true}
    ]
});

// The initialization of the advanced search fields.
export const advancedSearchFieldsAtom = atom({
    key: "advancedSearchFieldsKey",
    default: [
        {name: 'title', value: true},
        {name: 'year', value: true},
        {name: 'author', value: true},
        {name: 'abstract', value: false},
        {name: 'full-text', value: false}
    ]
});


export const createQueryFilterUrlString = (fields: any, urlParam: string) => { //  '&search-field='
    let fieldStr = ' ';
    // filter for only active fields.
    const fieldsToUse = fields.filter((d: { value: any; }) => d.value);
    // we must store only names in an array
    const arrayOfFieldsToUse: any[] = [];
    fieldsToUse.forEach((d: { name: any; }) => arrayOfFieldsToUse.push(d.name));

    for (let i = 0; i < arrayOfFieldsToUse.length; i++) {
        if (arrayOfFieldsToUse[i] !== '') {
            // first iteration
            if (i === 0) {
                // check if it has only one entry
                if (i + 1 === arrayOfFieldsToUse.length) {
                    fieldStr = urlParam + arrayOfFieldsToUse[i];
                } else {
                    fieldStr = urlParam + arrayOfFieldsToUse[i] + ',';
                }
            } else {
                if (i + 1 === arrayOfFieldsToUse.length) {
                    fieldStr += arrayOfFieldsToUse[i];
                } else {
                    fieldStr += arrayOfFieldsToUse[i] + ',';
                }
            }
        }
    }
    //console.log(fieldStr)
    return fieldStr;
}