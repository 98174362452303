import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {useRecoilState, useRecoilValue} from "recoil";
import {
    fetchAggregatedMakroLevelDataSelectorFamily
} from "../../../../../../dataProvider/trend-analytics/trend/trend";
import {genericSort, recursiveDeepClone} from "../../../../../../utility/utility";
import {useEffect, useRef, useState} from "react";
import SparklineContainer
    from "../../../../../VisualizationModules/Visualizations/Sparkline/SparklineContainer/SparklineContainer";
import {ToggleButton, ToggleButtonGroup} from "@mui/lab";
import MultilineChartIcon from "@mui/icons-material/MultilineChart";
import {Stack} from "@mui/material";
import LiteratureSearchBar
    from "../../../../LiteratureSearch/SimpleLiteratureSearch/LiteratureSearchBar/LiteratureSearchBar";
import DatabaseSwitch
    from "../../../../LiteratureSearch/SimpleLiteratureSearch/LiteratureSearchBar/DatabaseSwitch/DatabaseSwitch";

function TrendDetection() {
    const chartData = useRecoilValue(fetchAggregatedMakroLevelDataSelectorFamily("springer"));
    let newData = recursiveDeepClone(chartData);//.slice(0, 250);
    const [width, setWidth] = useState(null);
    const containerRef = useRef(null);
    const amountRows = 4;
    const [useNormalization, setUseNormalization] = useState(false);
    const [aggregation, setAggregation] = useState("trend");

    useEffect(() => {
        const handleResize = () => {
            // @ts-ignore
            setWidth(containerRef.current.getBoundingClientRect().width);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const aggregationNorm = aggregation + (useNormalization ? "Normalized" : "");
    newData = newData.sort(genericSort(aggregationNorm, "desc"))

    const handleAggregationChange = (
        event: React.MouseEvent<HTMLElement>,
        newAggregation: string | null,
    ) => {
        if (newAggregation !== null) {
            setAggregation(newAggregation);
        }
    };

    // d?.forecast?.evaluation?.hybrid?.MAPE
    // @ts-ignore
    return (
        <Container maxWidth="xl">
            <Grid container spacing={2}>
                <Grid item xs={4} sm={3} md={2}>
                    <Stack direction="row" spacing={4} sx={{mb: 2}}>
                        <DatabaseSwitch/>
                        <ToggleButton
                            value="normalization"
                            selected={useNormalization}
                            onChange={() => {
                                setUseNormalization(!useNormalization);
                            }}
                        >
                            <MultilineChartIcon></MultilineChartIcon>
                        </ToggleButton>
                        <ToggleButtonGroup
                            value={aggregation}
                            exclusive
                            onChange={handleAggregationChange}
                            aria-label="aggregation"
                        >
                            <ToggleButton value="trend" aria-label="trend">
                                trend
                            </ToggleButton>
                            <ToggleButton value="max" aria-label="max">
                                max
                            </ToggleButton>
                            <ToggleButton value="total" aria-label="total">
                                total
                            </ToggleButton>
                            <ToggleButton value="growth" aria-label="growth">
                                growth
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Grid>
            </Grid>

            <Grid ref={containerRef} container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                {
                    newData.map((d: any, i: number) => {
                        const forecastData = useNormalization ? d?.forecastNormalized?.data?.timeseries : d?.forecast?.data?.timeseries;

                        return (
                            <SparklineContainer
                                data={d}
                                forecastData={forecastData}
                                amountRows={amountRows}
                                width={width}
                                height={140}
                                index={i}
                            ></SparklineContainer>
                        )
                    })
                }
            </Grid>
        </Container>
    )
}

export default TrendDetection

/*
 <Grid item xs={3} key={d + '-' + i}>
                                <Paper elevation={3} sx={{height: 140}}>
                                    <Typography sx={{paddingLeft:2}}><b>{d.name}</b></Typography>

<Sparkline width={(width / amountRows)-30} height={90} color={"black"}
           data={forecastData}></Sparkline>
<LinearProgress
    variant="determinate"
    value={forecastAccuracy}

    color={color.toString()}
    style={{float: "right", marginRight: 5, height: "10px", width: "25%"}}
/>
</Paper>
</Grid>
*/