import Container from "@mui/material/Container";
import Header from "./Header/Header";
import Solutions from "./Solutions/Solutions";

function LandingPage() {
    return (
        <div>
                <Container maxWidth="xl">
                    <Header/>
                    <Solutions/>
                </Container>
        </div>
    )
}

export default LandingPage;