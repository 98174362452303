import * as React from "react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";


function SustainabilityApi() {

    return(
        <Container maxWidth="xl">
            <h1>SEMAVIS - Sustainability API</h1>
            <Divider/>
            <br/>
            <iframe src="https://api.morpheus.fbmd.h-da.de/" width={window.innerWidth - 300} height="750"></iframe>
        </Container>
    )
}
export default SustainabilityApi





