import Grid from "@mui/material/Grid";
import CustomCardGridItem from "../../UI/CustomCardGridItem/CustomCardGridItem";
import * as React from "react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import ThunderstormIcon from '@mui/icons-material/Thunderstorm';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const availableSolutions = [
    {
        solutionTitle: "Trend Analytics API",
        routeLink: "trend-analytics-api",
        roles: [],
        icon: <TrendingUpIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
    {
        solutionTitle: "Sustainability API",
        routeLink: "sustainability-api",
        roles: [],
        icon: <ThunderstormIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    }

]


function Apis() {
    // const keycloak = useKeycloak().keycloak;
    return(
        <Container maxWidth="xl">
            <h1>SEMAVIS - APIs</h1>
            <Divider/>
            <br/>
            <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                {
                    availableSolutions.map((d, i) => (
                        <CustomCardGridItem icon={d.icon} cardTitle={d.solutionTitle} routeLink={d.routeLink}
                                            roles={d.roles} key={i}/>
                    ))
                }
            </Grid>
        </Container>
    )
}
export default Apis





