import * as React from "react";
import Typography from "@mui/material/Typography";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MaiaLogo from "./logo/MaiaLogo";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function AiAssistant() {
    return (
        <Card sx={{minWidth: 275}} elevation={5} >
            <CardContent>
                <Grid container>
                    <Grid xs={4}/>
                    <Grid xs={6} >
                        <MaiaLogo height={20}></MaiaLogo>
                    </Grid>
                    <Grid xs={2}>
                        <ExpandMoreIcon color="primary" sx={{fontSize: "2em"}}/>
                    </Grid>
                </Grid>
                <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                    You might be interested in:
                </Typography>

                <Grid container>
                    <Grid xs={6}><b>image</b> </Grid>
                    <Grid xs={2} >
                        <AddCircleOutlineIcon></AddCircleOutlineIcon>
                    </Grid>
                    <Grid xs={2}>
                        <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={6}> <b>face</b> </Grid>
                    <Grid xs={2} >
                        <AddCircleOutlineIcon></AddCircleOutlineIcon>
                    </Grid>
                    <Grid xs={2}>
                        <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid xs={6}>    <b>recognition</b></Grid>
                    <Grid xs={2} >
                        <AddCircleOutlineIcon></AddCircleOutlineIcon>
                    </Grid>
                    <Grid xs={2}>
                        <RemoveCircleOutlineIcon></RemoveCircleOutlineIcon>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default AiAssistant