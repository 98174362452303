import * as React from "react";
import {facetSortingStateAtomFamily, leftNavigationFacetsAtom, previewAtom} from "./Facets-State";
import {
    activeFilterSetIdAtom, facetFiltersAtomFamily
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";
import {useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {sliderMinMaxValuesAtomFamily, sliderValuesAtomFamily} from "../YearSlider/YearSliderState";
import {recursiveDeepClone} from "../../../../../../utility/utility";
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ListItem, ListItemText, useTheme} from "@mui/material";
import {
    StyledAccordionDetails,
    StyledAccordionSummary
} from "../StyledComponents/StyledMuiAccordionElements/StyledAccordionElements";
import {
    searchDatabaseAtom,
    searchTermSelector
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/search";
import {useEffect} from "react";

export default function Facets() {
    // const searchResults = props.data;
    const rawData = useRecoilValue(leftNavigationFacetsAtom);
    //const maxAmount = configLeftNavigation.maxAmountFacetItems;
    //  filters are created on click on a facet
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [facetFilters, toggleFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    // const [previewState, togglePreviewState] = useRecoilState(previewAtom);
    // const setDisplayTopicInfo = useSetRecoilState(displayTopicInfoAtom);
    // const setSliderValues = useSetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    // const [displayTopicDistributionModal, setDisplayTopicDistributionModal] = useRecoilState(displayTopicDistributionModalAtom);
    // const setTopicInfoId = useSetRecoilState(topicInfoIdAtom);
    // const sliderValuesOnChange = useRecoilValue(sliderValuesSelectorFamily(activeFilterSetId))
    // const [usedManualSliderChange, setUsedManualSliderChange] = useRecoilState(manualSliderControlAtomFamily(activeFilterSetId));
    // const activePoiList = useRecoilValue(activePoiFiltersSelectorFamily(activeFilterSetId))
    const [facetSortingMethodOrder, setFacetSortingMethodOrder] = useRecoilState(facetSortingStateAtomFamily(activeFilterSetId));
    const theme = useTheme();

    const calculateData = () => {
        // if we have data...
        if (rawData.length > 0) {
            const clone = recursiveDeepClone(rawData);
            // generate preview.
            clone.map((d: { data: { [x: string]: any; allElements: string | any[]; }; }) => {
                return d.data['previewElements'] = d.data.allElements.slice(0, 3);
            });
            // active state for those that are active -> for highlighting purposes
            if (facetFilters !== undefined) {
                // set active state to those that are currently active.
                clone.map((d: { [x: string]: boolean; data: any }) => {
                    let hasFilter = false;
                    d.data.previewElements.map((e: { active: boolean; id: any; }) => {
                        facetFilters.forEach((f) => {
                            // @ts-ignore
                            if (f.facet === d.facet) {
                                // if value is in we set the corresponding element active.
                                // @ts-ignore
                                e.active = f.values.find((g) => g === e.id) !== undefined;
                                return e;
                            }
                        })
                        return e;
                    })

                    d.data.allElements.map((e: { active: boolean; id: any; }) => {
                        facetFilters.forEach((f) => {
                            // @ts-ignore
                            if (f.facet === d.facet) {
                                // if value is in we set the corresponding element active.
                                // @ts-ignore
                                e.active = f.values.find((g) => g === e.id) !== undefined;
                                if (e.active) {
                                    hasFilter = true;
                                }
                                return e;
                            }
                        })
                        return e;
                    })
                    d['hasFilter'] = hasFilter;
                    return d;
                })
            }
            return clone;
        } else {
            return []
        }
    }

    let data = calculateData();


    // reset all facets etc. on db or search string change






    const toggleFacetFilter = (facet: any, value: any) => {
        value = value.toString()
        const clone = recursiveDeepClone(facetFilters);
        // check if the facet is already created as another filter in that facet is created or sth like that.
        const isAlreadyCreated = clone.find((d: { facet: any; }) => {
            return d.facet === facet;
        });
        if (isAlreadyCreated !== undefined) {
            // check if we have to reset the slider.
            //if (facet === 'Years') {
            //    resetSliderValues();
            //}
            resetSliderValues();
            clone.map((d: { facet: any; values: any[]; }) => {
                if (d.facet === facet) {
                    // check if value is already set...
                    const valueAlready = d.values.find((e, i) => {
                        return e === value;
                    })
                    // if value is already set we remove it
                    if (valueAlready !== undefined) {
                        d.values = d.values.filter((d) => d !== value);
                    }
                    // if its new we add it.
                    else {
                        // d.value = d.value.push(value);
                        d.values.push(value);
                    }
                }

                return d;
            })
        } else {
            // check if we have to change the slider.
            // if (facet === 'Years') {
            //     setSliderValues([Number(value), Number(value)])
            // }
            clone.push({facet: facet, values: [value]});
        }

        //remove no longer used facets
        const filteredClone = clone.filter((d: { values: string | any[]; }) => d.values.length > 0);
        toggleFacetFilters(filteredClone);
    }

    /*
        const changeFacetAmountSorting = (data) => {
            const order = data.sorting.amountSorting.order;
            const activeSortingMethod = data.sorting.activeSortingMethod;
            const clone = _.cloneDeep(facetSortingMethodOrder);
            // setFacetSortingMethodOrder
            // if we are already sorting by amount we change the order.
            if (activeSortingMethod === 'amount') {
                // formattedData.sort(genericSort('amount', 'desc'));
                clone.map((d) => {
                    if (d.facet === data.facet) {
                        // change the order.
                        d.amountSorting.order = order === 'desc' ? 'asc' : 'desc';
                    }
                    return d;
                })
            }
            // otherwise we come here from az sorting and only want to acitvate amount sorting.
            else {
                clone.map((d) => {
                    if (d.facet === data.facet) {
                        // change the method.
                        d.amountSorting.method = 'amount';
                        d.activeSortingMethod = 'amount';
                    }
                    return d;
                })
            }
            setFacetSortingMethodOrder(clone);
        }

        const changeFacetAzSorting = (data) => {
            const order = data.sorting.azSorting.order;
            const activeSortingMethod = data.sorting.activeSortingMethod;
            const clone = _.cloneDeep(facetSortingMethodOrder);
            // setFacetSortingMethodOrder
            // if we are already sorting by amount we change the order.
            if (activeSortingMethod === 'az') {
                // formattedData.sort(genericSort('amount', 'desc'));
                clone.map((d) => {
                    if (d.facet === data.facet) {
                        // change the order.
                        d.azSorting.order = order === 'desc' ? 'asc' : 'desc';
                    }
                    return d;
                })
            }
            // otherwise we come here from az sorting and only want to acitvate amount sorting.
            else {
                clone.map((d) => {
                    if (d.facet === data.facet) {
                        // change the method.
                        d.azSorting.method = 'az';
                        d.activeSortingMethod = 'az';
                    }
                    return d;
                })
            }
            setFacetSortingMethodOrder(clone);
        }
    */

    // imports the styled subcomponents and uses the data from props
    const FacetAccordion = (props: { d: any; }) => {
        const d = props.d;
        // console.log(d)
        return (
            /* TransitionProps prevent performance problems with facets. */
            <Accordion TransitionProps={{unmountOnExit: true}} defaultExpanded={d.initialOpen}>
                <StyledAccordionSummary
                    sx={{m: 0, height: 50}}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{d.facet}</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    {d.data.allElements.map((e: any, i: number) => {
                        return (
                            <ListItem key={i} disablePadding
                                      onClick={() => toggleFacetFilter(d.facet, e.name)}
                                      style={{
                                          cursor: "pointer",
                                          backgroundColor: e.active ? theme.palette.primary.main : 'white'
                                      }}>
                                <ListItemText>
                                    <span>{e.name}</span>
                                    <span style={{float: "right"}}>{e.amount}</span>
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                </StyledAccordionDetails>
            </Accordion>
        );
    }

    return (
        <>
            {data.map((d: any) => {
                return (
                    <FacetAccordion d={d}></FacetAccordion>
                )
            })}
        </>
    );
}