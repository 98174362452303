import * as React from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import MainNavigation from "../../Navigations/MainNavigation/MainNavigation";
import Grid from "@mui/material/Grid";
import LeftNavigation from "../../Navigations/LeftNavigation/LeftNavigation";
import {styled} from "@mui/material/styles";
import {displayLeftNavigationAtom} from "../../Navigations/LeftNavigation/LeftNavigationState";
import LoadingSpinner from "../../../../UI/LoadingSpinner/LoadingSpinner";
import CircularSpinner from "../../../../UI/LoadingSpinner/CircularSpinner";
import SubNavigation from "../../Navigations/SubNavigation/SubNavigation";
import PublicationsGrid from "../../../../VisualizationModules/Visualizations/PublicationGrid/PublicationsGrid";


const drawerWidth = 240;

function LiteratureSearchDashboard() {
    const [open, setOpen] = useRecoilState(displayLeftNavigationAtom);

    const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
        open?: boolean;
    }>(({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0, //`-${drawerWidth}px`,
        marginTop: 60,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `+${drawerWidth}px`,
        }),
    }));


    return (
        <>
            <React.Suspense
                fallback={<LoadingSpinner/>
                }>
                <MainNavigation/>
            </React.Suspense>
            <React.Suspense
                fallback={<></>
                }>
                <SubNavigation/>
            </React.Suspense>
            <React.Suspense
                fallback={<CircularSpinner/>
                }>
                <LeftNavigation/>
            </React.Suspense>
            <Main open={open} sx={{mt: 10}}>
                <React.Suspense
                    fallback={<LoadingSpinner/>
                    }>
                    <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}>
                        <PublicationsGrid/>
                    </Grid>
                </React.Suspense>
            </Main>

        </>
    )
}

export default LiteratureSearchDashboard




