import React from 'react';
import './styles/App.scss';
import {RecoilRoot, useRecoilValue} from "recoil";
import {ReactKeycloakProvider} from "@react-keycloak/web";
import keycloak from "./dataProvider/authentication/keycloak";
import Routing from "./components/Routing/Routing";
import CssBaseline from '@mui/material/CssBaseline';
import CustomSnackBar from "./components/UI/CustomSnackBar/CustomSnackBar";
import RecoilNexus from "recoil-nexus";

function App() {

    return (
        <RecoilRoot>
            <RecoilNexus />
                    <ReactKeycloakProvider authClient={keycloak} initOptions={{
                        checkLoginIframe: false,
                        onLoad: 'check-sso',
                        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                        pkceMethod: 'S256',
                    }}>
                        <Routing/>
                        <CustomSnackBar/>
                    </ReactKeycloakProvider>
        </RecoilRoot>
    )
        ;
}

export default App;
