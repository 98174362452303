import React from "react";
import Grid from "@mui/material/Grid";
import HomeIcon from '@mui/icons-material/Home';
import reportingConfig from "./Features/Reporting/Reporting";

function VisualizationTopControls() {

  //  const handleItemClick = () => {
  //      console.log('Clicked menu item');
  //  };


    const items = [
        reportingConfig,
        {
            // top level
            label: 'Folder 1',
            icon: <HomeIcon />,
            onClick: () => console.log("home icon"),
            items: [
                { label: 'Sub-Folder 1', icon: <HomeIcon />,  onClick: () => console.log("sub home icon")},
                //    {
                //        label: 'Sub-Folder 2',
                //        icon: <HomeIcon />,
                //        onClick: handleItemClick,
                //        items: [
                //            { label: 'File 1', icon: <AccountCircleIcon />, onClick: handleItemClick, },
                //            { label: 'File 2', icon: <AccountCircleIcon />, onClick: handleItemClick, },
                //        ],
                //    },
            ],
        }
    ];

// <MultiLevelDropdown  items={items} />
    return(<>
        <Grid container sx={{alignItems: "center", justify: "center"}}
              columns={{xs: 4, sm: 8, md: 12}}>
            <Grid xs={1}>
                {/*  @ts-ignore */}

            </Grid>
            <Grid xs={1} >
                Grid 2
            </Grid>
        </Grid>

    </>)


} export default VisualizationTopControls;


/*
<Row>
    <Col xs={12}>
        <div className="grid-item-container-top-control">
            <div className="float-left" style={{ display: 'flex'}}>
                <Dropdown position="right"
                          title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                >
                    <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                        <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                         icon={faCamera}/> Screenshot Chart
                    </Dropdown.Item>
                </Dropdown>

                <div style={{marginLeft: 15, marginTop: 6}}>
                    {dataSets.map((d) =>
                        <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                               variant={d === activeDataset ? "primary" : "light"}
                               onClick={(e) => {
                                   changeDataSetToDisplay(d);
                               }}>
                            {d}
                        </Badge>
                    )}
                    {activeDataset === "monitoring" ? <FontAwesomeIcon style={{marginLeft: 15, cursor: "pointer"}}
                                                                       icon={faPlusCircle} onClick={() => setDisplayCreateNewMonitoringItemModal(true)}/> : null}
                </div>
            </div>

            {displayCreateNewMonitoringItemModal ? <CreateNewMonitoringItemModal/> : null}

            <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                e.preventDefault();
                props.deleteContainer(props.id);
            }}>X</Button>
        </div>
    </Col>
</Row>
 */