import * as React from "react";
import { InputBase, Paper} from "@mui/material";
import {useRecoilState} from "recoil";
import IconButton from "@mui/material/IconButton";
import {Link} from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import {useEffect, useState} from "react";
import {
    searchStringAtom,
    startSearchAtom
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/search";
import SearchSettings from "../SearchSettings/SearchSettings";



function SearchInput() {
    const [searchString, setSearchString] = useRecoilState(searchStringAtom);

    const [tempSearchString, setTempSearchString] = useState(searchString)

    console.log(tempSearchString)

    const [startSearch, setStartSearch] = useRecoilState(startSearchAtom);

    function handleSubmit(event: any) {
        event.preventDefault();
        setSearchString(tempSearchString);
        setStartSearch(true);

    }

    // reset start searching so it only fires on search button click...
    useEffect(() => {
        setStartSearch(false);
    },[startSearch])

    return (
        <>
            <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
                onSubmit={handleSubmit}
            >
                <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search..."
                    value={tempSearchString}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) =>  setTempSearchString(e.target.value)}
                />

                <IconButton onClick={(e)=> handleSubmit(e)} type="submit" sx={{ p: '10px' }} aria-label="search">
                    <Link to={"dashboard"} style={{textDecoration: "none"}}>
                        <SearchIcon />
                    </Link>
                </IconButton>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <SearchSettings/>
            </Paper>
        </>
    )
}

export default SearchInput

/*



 */