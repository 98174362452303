import React from 'react';
import {useRecoilState, useResetRecoilState} from "recoil";
import {poiAtomFamily} from "../../../../LeftNavigation/LeftNavigationState";
import {sliderValuesAtomFamily} from "../../../../LeftNavigation/YearSlider/YearSliderState";
import {recursiveDeepClone} from "../../../../../../../../utility/utility";
import {Badge} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";



function PoiFilters(props: { filterSetId: any; d: any; j: any; }) {
    // stores the stuff we display here.
    const [poiFilters, setPoiFilters] = useRecoilState(poiAtomFamily(props.filterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId));

    const deactivateElementFromFilterSet = (poi: { id: any; }, index: number) => {
        let clone = recursiveDeepClone(poiFilters);
        clone.forEach((d: { id: any; active: boolean; }) => {
            if (d.id === poi.id) {
                d.active = false;
            }
            return d;
        })
        setPoiFilters(clone);
        resetSliderValues();
        /*


        clone.forEach((d) => {
            if (d.facet === facet) {
                // filter out the value that was passed
                d.values = d.values.filter((e) => e !== filter);
                return d;
            }
            return d;
        });

        setPoiFilters(clone);
        resetSliderValues();
         */
    }


    const PoiFilters = () => {
        const d = props.d;
        const j = props.j;
        return (
            <>
                <Badge key={j}
                       style={{marginRight: 5, cursor: 'pointer'}}>
                    {'(POI) ' + d.name}
                </Badge>
                <IconButton style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}} onClick={() => {
                    deactivateElementFromFilterSet(d, j); // pass the facet, the id of filter
                }}>
                    <RemoveCircleIcon/>
                </IconButton>


            </>
        )
    }

    return (
        <PoiFilters/>
    );
}

export default PoiFilters;
