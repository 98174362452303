import Grid from "@mui/material/Grid";
import * as React from "react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import CustomCardGridItem from "../../../UI/CustomCardGridItem/CustomCardGridItem";
import {Alert} from "@mui/material";
import WorkshopImage from "./Images/WorkshopImage";

function GuidedCorporateStrategy() {
    return(
        <Container maxWidth="xl">
            <h1>Guided Corporate Strategy</h1>
            <Divider/>
            <br/>
            <Grid container justifyContent="center">
                <Grid item xs={6}>
                    <h3>Idea: Based on the workshop approach proposed by Pidun et al. we provide a guided approach.</h3>
                </Grid>
                <Grid item xs={6}>
                <WorkshopImage height={400} width={"100%"}></WorkshopImage>


                </Grid>
            </Grid>
        </Container>
    )
}
export default GuidedCorporateStrategy



