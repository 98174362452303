import {atom, selector, selectorFamily} from "recoil";
import {
    advancedSearchFieldsAtom,
    createQueryFilterUrlString,
    includeSelectionAtom
} from "../advanced-search/advanced-search";
import {searchDatabaseAtom, searchDataSelector, searchTermSelector} from "../search";
import {vissightsConfig} from "../../vissightsConfig";


export const initialAmountLoadedAtom = atom({
    key: "initialAmountLoadedAtom-Key",
    default: 100
})

export const firstLazyAmountLoadedAtom = atom({
    key: "firstLazyAmountLoadedAtom-Key",
    default: 1000
})

export const maximumAmountLoadedAtom = atom({
    key: "maximumAmountLoadedAtom-Key",
    default: 10000
})

export const useLazyLoadingAtom = atom({
    key: "useLazyLoadingAtom-Key",
    default: true
})

export const checkTotalAmount = selector({
    key: "checkTotalAmount-Key",
    get: async ({get}) => {
        const db = get(searchDatabaseAtom);
        const advancedSearchFields = get(advancedSearchFieldsAtom);
        const fieldsUrlStr = createQueryFilterUrlString(advancedSearchFields, '&search-field=');
        const includeSelection = get(includeSelectionAtom);
        const includeUrlStr = createQueryFilterUrlString(includeSelection, '&include=');
        const searchTerm = await get(searchTermSelector);
        const maximumAmountToReturn = get(maximumAmountLoadedAtom);

        let url = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/count?db=" + db.name + includeUrlStr + ',key' + "&limit=200" + "&q=" + searchTerm + fieldsUrlStr;
        console.log(encodeURI(url))
        // console.log(encodeURI(url));
        // url = encodeURI(url)
        const totalAmount = await fetch(encodeURI(url)).then((d) => {
            return d.json();
        });
        return totalAmount <= maximumAmountToReturn ? totalAmount : maximumAmountToReturn;
    }
});


// checks if data amount equals total amount. Returns boolean value
export const isStillLoadingDataSelector = selector({
    key: "isStillLoadingDataSelector-Key",
    get: ({get}) => {
        const searchAmount = get(searchDataSelector);
        const totalAmount = get(checkTotalAmount);
        return !(searchAmount.length === totalAmount);
    }
});


// here we load first n elements for the search
export const lazyLoadingConfigSelectorFamily = selectorFamily({
    key: "lazyLoadingConfigSelectorFamily-Key",
    get: lazyConfig => ({get}) => {
        const lazyLoadingConfig = [];

        let iteration = 1;
        // we create packages of 500 for lazy loading. This must be conducted as the api only allows 500 full-text answers
        // @ts-ignore
        for (let i = 500; i <= lazyConfig.amount + 500; i = i + 500) {
            lazyLoadingConfig.push({
                name: "lazy-iteration-" + iteration,
                amount: 500,
                offset: i - 500,
                // @ts-ignore
                searchTerm: lazyConfig.searchTerm
            })
            // add one to iteration
            iteration++;
        }
        return lazyLoadingConfig;
    }
});

