import * as React from "react";
import {useRecoilState} from "recoil";
import {currentThemeAtom} from "../../../../../styles/themes/themes";
import {DarkModeOutlined, LightModeOutlined} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function ThemeSwitch() {
    const [currTheme, setCurrTheme] = useRecoilState(currentThemeAtom);

    return (

        <Tooltip title="Change Theme">
            <IconButton
                onClick={() => currTheme === "light" ? setCurrTheme("dark") : setCurrTheme("light")}
                size="small"
                sx={{minHeight: 0, minWidth: 0, pt: 0, ml: "auto", mr: 0}}
            >
                {
                    currTheme !== "light" ?
                        <LightModeOutlined color="secondary" sx={{fontSize: "1.4em", mb: 0.2, ml: 1}} />
                        :
                        <DarkModeOutlined color="secondary" sx={{fontSize: "1.4em", mb: 0.2, ml: 1}}/>
                }
            </IconButton>
        </Tooltip>


    )
}

export default ThemeSwitch;