import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import * as React from "react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CustomCardGridItem from "../../UI/CustomCardGridItem/CustomCardGridItem";

function LiteratureSearch() {

    const availableSolutions = [
        {
            solutionTitle: "Literature Search",
            routeLink: "simple-literature-search",
            roles: ["full_access_user"],
            icon: <MenuBookOutlinedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
        },
        {
            solutionTitle: " Scientific Search 2.0",
            routeLink: "search-two",
            roles: [],
            icon: <SavedSearchIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
        },
        {
            solutionTitle: "Guided PRISMA",
            routeLink: "guided-prisma",
            roles: ["full_access_user"],
            icon: <AutoStoriesOutlinedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
        }
    ]

    return (
        <Container maxWidth="xl">
            <h1>SEMAVIS - Literature Search Solutions</h1>
            <Divider/>
            <br/>
            <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                {
                    availableSolutions.map((d, i) => (
                        <CustomCardGridItem icon={d.icon} cardTitle={d.solutionTitle} routeLink={d.routeLink}
                                            roles={d.roles} key={i}/>
                    ))
                }
            </Grid>
        </Container>
    )
}

export default LiteratureSearch