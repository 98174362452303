import CameraIcon from '@mui/icons-material/Camera';
import * as _ from "lodash";
import {v4 as uuid4} from 'uuid';
import {getRecoil, setRecoil} from "recoil-nexus";
import {singleVisualizationScreenshotsAtom} from "../reporting-state";
import {snackBarAtom} from "../../../../../../../UI/CustomSnackBar/CustomSnackBar";
import domtoimage from "dom-to-image";
import {recursiveDeepClone} from "../../../../../../../../utility/utility";




const createVisualizationScreenshot = () => {

    const snackBarState = getRecoil(snackBarAtom);
    console.log("should create screenshot", snackBarState)

    const snackBarStateCopy = recursiveDeepClone(snackBarState)

    setRecoil(snackBarStateCopy, {color: "error", duration: 8000, horizontal: "right", message: "Successfully saved Visualization Screenshot.", vertical: "bottom" , open: true})

    }

export default createVisualizationScreenshot;



   // // const node = document.getElementById(componentId);
   // domtoimage.toPng(document.getElementById("visualization-container"))
   //     .then((dataUrl) => {
   //         const newUUID = uuid4();
   //         // store the image, the module, and the current database
   //         const autoGeneratedText = 'The following chart was taken in the overview Module';
   //         // 'searching for' + searchTerm + ' in the database: ' + db.name + '. ' +
   //         //  'It shows a temporal overview.';
   //         const metaDataObj = {id: newUUID, dataUrl, module, autoGeneratedText}
//
   //         const clone = _.cloneDeep(getRecoil(singleVisualizationScreenshotsAtom));
   //         clone.push({id: newUUID, data: metaDataObj});
   //         setRecoil(getRecoil(singleVisualizationScreenshotsAtom),clone);
   //     }).then(() => setRecoil(getRecoil(snackBarAtom),(prevState) => ({
   //     ...prevState,
   //     message: "Successfully saved Visualization Screenshot.",
   //     open: true,
   // })));


