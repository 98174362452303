import Grid from "@mui/material/Grid";
import {Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useRecoilValue} from "recoil";
import {filteredDataSelector} from "../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";


function PublicationsGrid() {
    const rawSearchData = useRecoilValue(filteredDataSelector);
    return (
        <>
            {
                rawSearchData.map((d: any, i: number) => {
                    return (
                        <Grid item xs={2} key={d + '-' + i}>
                            <Paper elevation={3} sx={{height: 120, textAlign: "center"}}>
                                <Typography>{d.title[0].slice(0, 20) + "..."}</Typography>
                            </Paper>
                        </Grid>
                    )
                })
            }
        </>
    )
}

export default PublicationsGrid;