import React, {useRef, useEffect} from 'react';
import * as d3 from 'd3';
import {deviation} from 'd3-array';

const Sparkline = ({theme, width, height, data, color}) => {
    const ref = useRef();

    useEffect(() => {
        const svg = d3.select(ref.current);
        renderSparkline(svg, width, height, data, color, theme);
    }, [width, height, data, color,theme]);

    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            //transform to center the svg in the paper
            transform={"translate(3,0)"}
            style={{backgroundColor:  theme.palette.background.default
        }}
        />
    );
};

const renderSparkline = (svg, width, height, data, color, theme) => {
    svg.selectAll('*').remove();
    // @TODO: Must be refactored to backend!
    if (data !== undefined) {
        data = [...data.slice(0, -5), ...data.slice(-5).map(item => ({...item, forecast: true}))];


        // Calculate standard deviation for the last 5 years
        const forecastData = data.slice(-5).map(d => d.amount);
        const stddev = deviation(forecastData);
        // console.log(forecastData, stddev)
        // Update data points with the standard deviation
        const dataWithStdDev = data.map((d, i) => {
            if (d.forecast) {
                return {...d, stddev};
            }
            return d;
        });

        const x = d3.scaleLinear().domain([0, data.length - 1]).range([0, width]);
        const y = d3.scaleLinear().domain(d3.extent(data, d => d.amount)).range([height, 15]);

        const line = d3
            .line()
            .x((_, i) => x(i))
            .y((d) => y(d.amount))
            .curve(d3.curveLinear);
        // .curve(d3.curveMonotoneX);
        // Find the last non-forecast data point and the last forecast data point
        const lastNonForecast = dataWithStdDev.filter(d => !d.forecast).slice(-1)[0];
        const lastForecast = dataWithStdDev.filter(d => d.forecast).slice(-1)[0];

        // Set the color based on the rule
        const lineColor = lastNonForecast.amount < lastForecast.amount ? 'green' : 'red';

        svg
            .append('path')
            .datum(data)
            .attr('fill', 'none')
            .attr('stroke', lineColor)
            .attr('stroke-width', 1)
            .attr('d', line);

        // Area for standard deviation
        const area = d3
            .area()
            .x((_, i) => x(i))
            .y0((d) => y(d.amount - (d.forecast ? d.stddev : 0)))
            .y1((d) => y(d.amount + (d.forecast ? d.stddev : 0)))
            .curve(d3.curveLinear);
        // .curve(d3.curveMonotoneX);

        svg
            .append('path')
            .datum(dataWithStdDev)
            .attr('fill', 'rgba(128, 128, 128, 0.25)')
            .attr('stroke', 'none')
            .attr('d', area);

        svg
            .append('path')
            .datum(dataWithStdDev)
            .attr('fill', 'rgba(128, 128, 128, 0.25)')
            .attr('stroke', 'none')
            .attr('d', area);

        // Vertical line
        const verticalLine = svg
            .append('line')
            .attr('x1', 0)
            .attr('x2', 0)
            .attr('y1', 0)
            .attr('y2', height)
            .attr('stroke', 'black')
            .attr('stroke-width', 1)
            .style('display', 'none');

        // Tooltip
        const tooltip = d3
            .select('body')
            .append('div')
            .style('position', 'absolute')
            .style('background-color', theme.palette.background.paper)
            // .style('background-color', 'white')
            .style('border', '1px solid black')
            .style('padding', '5px')
            .style('display', 'none');
        // Mouse events
        svg
            .on('mousemove', (event) => {
                const mouseX = d3.pointer(event)[0];
                const index = Math.round(x.invert(mouseX));
                const dataPoint = dataWithStdDev[index];

                if (dataPoint) {
                    verticalLine.attr('x1', x(index)).attr('x2', x(index));

                    let tooltipHtml = `Year: ${dataPoint.year}<br>Amount: ${dataPoint.amount}`;
                    if (dataPoint.forecast) {
                        tooltipHtml += `<br>Standard Deviation: ${dataPoint.stddev.toFixed(2)}`;
                    }

                    tooltip
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY + 10}px`)
                        .style('display', 'inline')
                        .html(tooltipHtml);
                }
            })
            .on('mouseover', () => {
                verticalLine.style('display', 'inline');
            })
            .on('mouseout', () => {
                verticalLine.style('display', 'none');
                tooltip.style('display', 'none');
            });

        svg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .style('fill', 'none')
            .style('pointer-events', 'all');
    }
};

export default Sparkline;


/*

import React, {useRef, useEffect, useState} from "react";
import * as d3 from "d3";

const SparklineChart = (props) => {
    const svgRef = useRef(null);
    const [tooltipData, setTooltipData] = useState(null);
    const data = props.data;
    const width = props.width;
    const height= props.height;
    const color = "darkgrey";


    const handleMouseMove = (event) => {
        const bisect = d3.bisectLeft(data.map((d) => d.year), xScale.invert(event.offsetX));
        const d = data[bisect];
        const tooltipX = xScale(d.year);
        const tooltipY = yScale(d.amount);

        setTooltipData({ x: tooltipX, y: tooltipY, data: d });
    };

    const handleMouseOut = () => {
        setTooltipData(null);
    };

    //useEffect(() => {
        const svg = d3.select(svgRef.current);

        // Set the dimensions of the chart
        svg.attr("width", width).attr("height", height);

        const line = d3
            .line()
            .x((d) => xScale(d.year))
            .y((d) => yScale(d.amount));

        const xScale = d3
            .scaleTime()
            .domain(d3.extent(data, (d) => d.year))
            .range([0, width]);

        const yScale = d3
            .scaleLinear()
            .domain([0, d3.max(data, (d) => d.amount)])
            .range([height, 0]);

        // Select the existing path element and remove it
        svg.select("path").remove();


        // Draw the line
        svg
            .append("path")
            .datum(data)
            .attr("fill", "none")
            .attr("stroke", color)
            .attr("stroke-width", 2)
            .attr("d", line);



   // }, [data, width, height, color]);

    return (
        <svg
            ref={svgRef}
            width={width}
            height={height}
            onMouseMove={handleMouseMove}
            onMouseOut={handleMouseOut}
        >
            <path
                d={line(data)}
                fill="none"
                stroke={color}
                strokeWidth="2"
                strokeLinejoin="round"
                strokeLinecap="round"
            />
            {tooltipData && (
                <>
                    <line
                        x1={tooltipData.x}
                        y1={0}
                        x2={tooltipData.x}
                        y2={height}
                        stroke="#333"
                        strokeWidth="1"
                        strokeDasharray="3"
                    />
                    <line
                        x1={0}
                        y1={tooltipData.y}
                        x2={width}
                        y2={tooltipData.y}
                        stroke="#333"
                        strokeWidth="1"
                        strokeDasharray="3"
                    />
                    <circle
                        cx={tooltipData.x}
                        cy={tooltipData.y}
                        r="5"
                        fill={color}
                        stroke="#fff"
                        strokeWidth="2"
                    />
                    <g transform={`translate(${tooltipData.x}, ${tooltipData.y - 30})`}>
                        <rect
                            x="0"
                            y="0"
                            width="80"
                            height="20"
                            fill="#333"
                            opacity="0.7"
                            rx="5"
                        />
                        <text x="5" y="15" fill="#fff" fontWeight="bold">
                            {tooltipData.data.amount}
                        </text>
                    </g>
                </>
            )}
        </svg>)
};

export default SparklineChart;

import React, {useEffect, useRef} from "react";
import * as d3 from 'd3';
import {useKeycloak} from "@react-keycloak/web";

function Sparkline(props) {
    // const componentId = props.id;
    const ref = useRef();
    let containerWidth = props.width;
    let containerHeight = props.height;
    const results = props.results;
    const {keycloak} = useKeycloak();
    const isLoggedIn = keycloak.authenticated;

    // return isLoggedIn ? children :
    useEffect(() => {
        console.log("use Effect", results);
        removeChart();
        createChart();
        createChart();
    })//,[props.width, props.height, props.results]) //

    // const scaleXY = () => {
    //     const w = containerWidth;
    //     const h = containerHeight;
    //     const data = results;
    //     const [minX, maxX] = d3.extent(data, (e) => e.year);
    //     const maxY = d3.max(data, (d) => d.amount);
//
    //     console.log(minX, maxX, maxY)
//
    //     return {
    //         x: d3.scaleBand()
    //             .domain(d3.range(minX, maxX + 1))
    //             .range([10, w], 0.5),
    //         y: d3.scaleLinear()
    //             .domain([0, maxY])
    //             .range([h - 20, 1])
    //     };
    // }
//


    const createChart = () => {
        const svg = d3.select(ref.current);

        const [minX, maxX] = d3.extent(results, (e) => e.amount);
        const maxY = d3.max(results, (d) => d.year);

        const xScale = d3
            .scaleLinear()
            .domain([minX, maxX])
            .range([0, containerWidth]);

        const yScale = d3
            .scaleLinear()
            .domain([0, maxY])
            .range(0, containerHeight);

        svg
            .attr("width", containerWidth)
            .attr("height", containerHeight)
            .append("g");


        const line = d3
            .line()
            .x((d) => xScale(d.amount))
            .y((d) => yScale(d.year));

        svg
            .append("path")
            .datum(results)
            .attr("fill", "none")
            .attr("stroke", "#bbb")
            .attr("stroke-width", 1)
            .attr("d", line);

        //     const svg = d3.select(ref.current);
//
        //     const scales = scaleXY();
//
        //     const x = (d) => scales.x(d.amount);
        //     const y = (d) => scales.y(d.year);
//
        //     svg
        //         .attr('width', containerWidth)
        //         .attr('height', containerHeight);
//
//
        //     const line = d3.line()
        //         .x(x)
        //         .y(y)
        //         .curve(d3.curveMonotoneX)
//
        //     const path = svg
        //         .selectAll('g')
        //         .select('.points')
        //         .selectAll('path')
        //         .data(results);
//
        //     console.log(path)
//
        //     path
        //         .enter()
        //         .append('path')
        //         .attr('opacity', 1)
        //         .attr('fill', 'none')
        //         .attr('stroke-width', 2)
        //         .attr('cursor', 'pointer')
        //         //.on('click', (d) => onClick(d))
        //         //.attr('stroke', (d) => topicLineFacetOutlineColor(d.color))// (d) => d.hovered === true ? 'red' : 'blue')
        //         .attr('d', line)
        //         // .append('title')
        //         // .text((d) => d.name);
//
//
        //     svg
        //         .select('g')
        //         .select('.points')
        //         .selectAll('path')
        //         .attr('transform', 'translate(' + scales.x.bandwidth() / 2 + ',' + 0 + ')');
//
//
        //     path.exit()
        //         .transition()
        //         .attr('opacity', 0)
        //         .remove();
//
    }


    const removeChart = () => {
        d3.select(ref.current)
            .selectAll('g')
           //.select('.points')
           //.selectAll('path')
            .remove();
        d3.select(ref.current)
            .selectAll('g')
            .selectAll('path')
           // .selectAll('.texts')
           // .selectAll('text')
            .remove();
    }
    /*
    <g>
        <g className='points'/>
        <g className='axis'/>
        <g className='yaxis'/>
        <g className='lines'/>
        <g className='texts'/>
    </g>


return (
    <svg id="visualization-container" ref={ref}>

    </svg>
);
}

export default Sparkline;


*/