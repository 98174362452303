import {useKeycloak} from "@react-keycloak/web";
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid";
import {Alert} from "@mui/material";
import routes from "../../../components/Routing/routes";
import {Link} from "react-router-dom";

const PrivateRoute = ({childs, rolesAllowed}) => {
    const {keycloak} = useKeycloak();
    const isLoggedIn = keycloak.authenticated;
    const userName = keycloak?.tokenParsed?.preferred_username
    // client id differs between dev and prod env -> is used to access correct clientId
    const clientId = process.env.NODE_ENV !== 'production' ? "react-front-end-development" : "react-front-end-production";
    const userRoles = keycloak?.tokenParsed?.realm_access.roles;

    console.log(keycloak, keycloak?.tokenParsed)


    const UserLoginComponent = () => (
        <>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                    <h1>Please login to continue.</h1>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                    <Button variant="contained" onClick={() => keycloak.login()}>Login</Button>
                </Grid>
            </Grid>
        </>
    )


    const UserNotAuthorized = () => (
        <>
            <Grid container justifyContent="center">
                <Grid item>
                    <Alert severity="error">Permission denied!</Alert>
                    <p>Sorry <b>{userName ? userName : "Unknown User"}</b>, seems like you don't have the necessary access rights for this site</p>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                    <Link to={"/"}>
                        <Button variant="contained">
                            Back to Home
                        </Button>
                    </Link>
                </Grid>
            </Grid>
        </>
    )


    // returns true if user is admin or if his role appears in the allowed roles array.
    const hasAccessRight = () => {
      //  if(rolesAllowed.length === 0 || (rolesAllowed.length === 1 && rolesAllowed[0] === "limited_access_user")){
       //     return true;
       // }
        if (userRoles.some(d => rolesAllowed.indexOf(d) === 'admin')) {
            return true;
        }
        else return userRoles.some(d => rolesAllowed.indexOf(d) >= 0);
    }
    // if user is logged in and has the correct access rights he sees the content, otherwise he sees the login component.
    return isLoggedIn && hasAccessRight() ?
        childs :
        isLoggedIn && !hasAccessRight() ? <UserNotAuthorized/> : <UserLoginComponent/>

};

export default PrivateRoute;