import {atom} from "recoil";

export const displayMetaNavigationFullHeightAtom = atom({
    key: "displayMetaNavigationFullHeightAtom-Key",
    default: true
});

export const pinnedState = atom({
    key: 'pinnedState',
    default: true,
});

// Has to have an export to make it a module?!
export {}