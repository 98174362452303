import {atom, selectorFamily} from "recoil";
import somData from './somData.json';
import {searchDatabaseAtom} from "../zoomAndFilter/search/search";





export const fetchAggregatedMakroLevelDataSelectorFamily = selectorFamily({
    key: 'fetchAggregatedMakroLevelDataSelectorFamily-Key',
    /**  db
     * @param db
     */
    get:  db => async ({get}) => {
        try {
            // @ts-ignore
            const currDb = get(searchDatabaseAtom)
            const topicOverviewDataUrl =  "https://api.morpheus.fbmd.h-da.de/v1/trend?db=" + currDb.name;// vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/topicstats?db=" + db.name;
            const aggregatedOverviewData = await fetch(topicOverviewDataUrl);
            return aggregatedOverviewData.json()
        } catch (error) {
            throw error;
        }
    }
});


export const fetchAggregatedMakroLevelDataGroupedSelectorFamily = selectorFamily({
    key: 'fetchAggregatedMakroLevelDataGroupedSelectorFamily-Key',
    /**  db
     * @param db
     */
    get: db => async ({get}) => {
        try {

            const groupedData: any = [];
            const ObjectValues = Object.values(somData);

            // som data comes in nested structure. First we need to access and iterate over the object values.
            ObjectValues.map((d,i) => {
                const innerGroup: any = [];
                // ts-ignore
                d.map((e,j) => {
                    // for testing purposes we initially load 10
                    if(j < 10){
                        innerGroup.push(e[0])
                    }

                })
                // ts-ignore
                groupedData.push(innerGroup)
            })

            // @ts-ignore
            // const groupedTopicOverviewDataUrl =  "https://api.morpheus.fbmd.h-da.de/v1/som?db=" + db.toString() + "&num-groups=3&forecast=false&normalized=false";// vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/topicstats?db=" + db.name;
            // const aggregatedGroupedData = await fetch(somData);
            return groupedData;//.json(); //
        } catch (error) {
            throw error;
        }
    }
});