import * as React from "react";
import {useRecoilValue} from "recoil";
import {
    checkTotalAmount, isStillLoadingDataSelector
} from "../../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/lazy-loading/lazy-loading";
import {
    amountRawDataSelector
} from "../../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/facetation/facetation";
import {
    amountFilteredDataSelector
} from "../../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";

function AmountLoadingInfos() {
    const isStillLoadingData = useRecoilValue(isStillLoadingDataSelector);
    const fullAmount = useRecoilValue(checkTotalAmount);
    const resultAmount = useRecoilValue(amountRawDataSelector);
    const filteredResultsAmount = useRecoilValue(amountFilteredDataSelector);

    return (
        <>
         <span style={{paddingRight: 3}}>
                Results:
            </span>
            {
                isStillLoadingData ?
                    <b>{filteredResultsAmount}/{resultAmount}</b>
                    :
                    <b>{resultAmount} </b>
            }
            {
                isStillLoadingData ?
                    <div>
                        <span style={{marginLeft: 5}}><b>{' '}/ {fullAmount}</b></span>
                        <div style={{marginLeft: 5}} className="lds-ring">
                            <div></div>
                        </div>
                    </div> : null
            }
        </>
    )
}

export default AmountLoadingInfos