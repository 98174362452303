import {atom, RecoilValueReadOnly, selector, selectorFamily, waitForAny} from "recoil";

import {
    assistedSearchAtom,
    assistedSearchQuerySelectorFamily,
} from "./assisted-search/assisted-search";
import {
    advancedSearchFieldsAtom,
    createQueryFilterUrlString,
    includeSelectionAtom
} from "./advanced-search/advanced-search";
import {checkTotalAmount, lazyLoadingConfigSelectorFamily, maximumAmountLoadedAtom} from "./lazy-loading/lazy-loading";
import {recoilPersist} from "recoil-persist";
import {initialDb, vissightsConfig} from "../vissightsConfig";
import {recursiveDeepClone} from "../../../../utility/utility";
import FACET from "../facet-extraction-utility";
const {persistAtom} = recoilPersist()


// checks if search shall be started!
export const startSearchAtom = atom({
    key: "startSearchAtom-Key",
    default: false,
    effects: [
        ({onSet}) => {
            onSet(newID => {
                console.debug("set start search", newID);
            });
        },
    ],
});

// the current search string
export const searchStringAtom = atom({
    key: "searchStringAtom-Key",
    default: "",
    effects_UNSTABLE: [persistAtom]
});

// save available databases in an atom
export const availableDatabasesAtom = atom({
    key: "availableDatabasesKey",
    // already filtered dbs with icons and display name
    default: vissightsConfig.dbConfig // fetchAvailableDatabases,
});

// stores the current database
export const searchDatabaseAtom = atom({
    key: "searchDatabaseKey",
    default: initialDb,
});


export const searchTermSelector = selector({
    key: "searchTermSelector-Key",
    get: async ({get}) => {
        //const shouldStartSearch = get(startSearchAtom);
        const searchTerm = get(searchStringAtom);
        const useAssisted = get(assistedSearchAtom);
       // if(shouldStartSearch) {
            if (useAssisted) {
                return await get(assistedSearchQuerySelectorFamily(searchTerm));
            } else {
                return searchTerm;
            }
        // }
    }
});


// returns all search data
// @ts-ignore
export const searchDataSelector = selector({
    key: "searchDataSelector-Key",
    get: async ({get}) => {
        // const useLazyLoading = get(useLazyLoadingAtom);
        const maximumAmountLoaded = get(maximumAmountLoadedAtom);
        //  const useAssistedSearch = get(assistedSearchAtom);
        let dataToReturn = [];

        // search term selector pre checks is we use assisted search and returns suitable query
        let searchTerm = get(searchTermSelector);
        let totalAnswerAmountForQuery;

        totalAnswerAmountForQuery = await get(checkTotalAmount);

        // we initially query for the full amount of answers for our query.
        // only use lazy loading if we have over 400 elements
        const useLazyLoading = totalAnswerAmountForQuery > 400;
        console.log("full amount of results", totalAnswerAmountForQuery);

        // case lazyloading
        if (useLazyLoading) {

            const lazyLoadingConfigSelector = get(lazyLoadingConfigSelectorFamily({
                amount: totalAnswerAmountForQuery,
                searchTerm: searchTerm
            }))

            const lazyLoadingConfig = recursiveDeepClone(lazyLoadingConfigSelector);

            console.log(lazyLoadingConfig)

            //const lazyLoadingConfig = [
            //    {name: "initial", amount: initialAmountLoaded, offset: 0, searchTerm: searchTerm},
            //    {
            //        name: "first-lazy",
            //        amount: firstLazyAmountLoaded,
            //        offset: Number(initialAmountLoaded),
            //        searchTerm: searchTerm
            //    },
            //    {
            //        name: "second-lazy",
            //        amount: maximumAmountLoaded,
            //        offset: (firstLazyAmountLoaded + initialAmountLoaded),
            //        searchTerm: searchTerm
            //    }
            //]
//

            // let searchData = get(waitForAny(
            //
            //     for(let i = 0; i < totalAnswerAmountForQuery;  i=i+500){
            //     searchForTerm({
            //         amount: d.amount,
            //         offset: d.offset,
            //         searchTerm: d.searchTerm
            //     })
            // }));

            let searchData = get(waitForAny(lazyLoadingConfig.map((d: { data: RecoilValueReadOnly<any>; amount: any; offset: any; searchTerm: any; }) => d.data = searchForTerm({
                amount: d.amount,
                offset: d.offset,
                searchTerm: d.searchTerm
            }))));


            let returnData: any[] = [];


            // @ts-ignore
            searchData.forEach((d, i) => {
                if (d.state === 'hasValue') {
                    returnData.push(d.contents)
                }
            })

            // if(tempReturnData.length === 1){
            //     returnData = tempReturnData;
            // } else if(tempReturnData.length >= lazyLoadingConfig.length / 3 && tempReturnData.length <= lazyLoadingConfig.length / 2) {
            //     returnData = tempReturnData;
            // } else if(tempReturnData.length ===  lazyLoadingConfig.length){
            //     returnData = tempReturnData;
            // } else {
            //     return;
            // }


            dataToReturn = returnData.flat();

            // checks if first lazy load answer is there
            // if (searchData[1].state === 'hasValue') {
            //     dataToReturn = searchData[0].contents.concat(searchData[1].contents);
            // }
            // // checks if second lazy load answer is there
            // else if (searchData[2].state === 'hasValue') {
            //     dataToReturn = searchData[0].contents.concat(searchData[1].contents).concat(searchData[2].contents);
            //
            // }
            // // else return data
            // else {
            //     dataToReturn = searchData[0].contents;
            // }

        } else {
            // @ts-ignore
            dataToReturn = await get(searchForTerm({amount: maximumAmountLoaded, offset: 0, searchTerm: searchTerm}))
        }

        return dataToReturn;
    }
});

export const searchDataAtom = atom({
    key: "searchDataAtom-Key",
    default: searchDataSelector
})

// the selector that fetches the remote data from the API
export const searchForTerm = selectorFamily({
    key: "searchForTerm",
    get: (queryData: { amount: string; offset: string; searchTerm: string; }) => async ({get}) => {
        const db = get(searchDatabaseAtom);
        const advancedSearchFields = get(advancedSearchFieldsAtom);
        const fieldsUrlStr = createQueryFilterUrlString(advancedSearchFields, '&search-field=');
        const includeSelection = get(includeSelectionAtom);
        const includeUrlStr = createQueryFilterUrlString(includeSelection, '&include=');
        let url = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + includeUrlStr + ',key' + "&limit=" + queryData.amount + "&offset=" + queryData.offset + "&q=" + queryData.searchTerm + fieldsUrlStr;
        console.log(encodeURI(url))

        const d = await fetch(encodeURI(url));
        return await d.json();
    }
});



