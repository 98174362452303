import Container from "@mui/material/Container";
import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CustomStepper from "./Stepper/Stepper";
import IdentificationExample from "./Steps/IdentificationExample";


function GuidedPrisma() {

    return (
        <Container maxWidth="xl">
            <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
             <IdentificationExample/>
            </Grid>
            <CustomStepper></CustomStepper>
        </Container>
    )
}
export default GuidedPrisma