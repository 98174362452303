import React from 'react';
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import { topicsAtom } from '../../../../../../../../dataProvider/trend-analytics/general/topics/topics';
import { facetFiltersAtomFamily } from '../../../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters';
import {sliderValuesAtomFamily} from "../../../../LeftNavigation/YearSlider/YearSliderState";
import {recursiveDeepClone} from "../../../../../../../../utility/utility";
import {Badge} from "@mui/material";
import FACET from "../../../../../../../../dataProvider/trend-analytics/zoomAndFilter/facet-extraction-utility";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";



function FacetFilters(props: any) {
    const topics = useRecoilValue(topicsAtom);
    // stores the stuff we display here.
    const [facetFilters, setFacetFilters] = useRecoilState(facetFiltersAtomFamily(props.filterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId));

    const removeElementFromFilterSet = (facet: any, filter: any) => {
        // console.log(filter, filterset, filterSetIndex)
        // d.facet, e, filterSetIndex
        // const filterSetToUpdate = formattedFilterSets[filterSetIndex]
        // console.log(facetFilters)
        let clone = recursiveDeepClone(facetFilters);

        clone.forEach((d: { facet: any; values: any[]; }) => {
            if (d.facet === facet) {
                // filter out the value that was passed
                d.values = d.values.filter((e) => e !== filter);
                return d;
            }
            return d;
        });

        setFacetFilters(clone);
        resetSliderValues();
    }

    const FacetFilters = () => {
        // console.log("test",props);
        const d = props.d;
        const j = props.j;
        return (
            d.values.map((e: any, i: number) =>
                <>
                    <Badge key={d.facet + j}
                           style={{
                               marginRight: 3,
                               cursor: 'pointer'
                           }}>
                    <span key={i} style={{marginRight: 5}}>
                        {d.facet === FACET.TOPIC ? FACET.getTopicNameById(topics, e) : e}
                    </span>
                    </Badge>

                    <IconButton style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}} onClick={() => {
                        removeElementFromFilterSet(d.facet, e); // pass the facet, the id of filter
                    }}>
                        <RemoveCircleIcon/>
                    </IconButton>
                </>
            )
        )
    }


    return (
        <FacetFilters/>
    );
}

export default FacetFilters;
