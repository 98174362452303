const DATA = {
    Key(o) {
		if(o.hasOwnProperty("key")){
			return o.key;
        } else {
            return o.id;
        }
    },
    Weight(o) {
        return o.weight;
    },
    Title(o) {
        const t = o.title;
        return t === undefined ? undefined : t[0];
    },
    Year(o) {
        const y = o.year;
        return y === undefined ? undefined : y[0];
    },
    Authors(o) {
        return o.author || [];
    }
};
export default DATA;