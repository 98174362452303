import Grid from "@mui/material/Grid";
import CustomCardGridItem from "../../UI/CustomCardGridItem/CustomCardGridItem";
import * as React from "react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import {QueryStatsOutlined} from "@mui/icons-material";
const availableSolutions = [
    {
        solutionTitle: "Guided Corporate Strategy",
        routeLink: "guided-corporate-strategy",
        roles: ["full_access_user"],
        icon: <FollowTheSignsIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
    {
        solutionTitle: "Corporate Foresight",
        routeLink: "corporate-foresight",
        roles: ["full_access_user"],
        icon: <QueryStatsOutlined color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    }
]
function CorporateStrategy() {
    // const keycloak = useKeycloak().keycloak;
    return(
        <Container maxWidth="xl">
            <h1>SEMAVIS - Corporate Strategy</h1>
            <Divider/>
            <br/>
                <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                      columns={{xs: 4, sm: 8, md: 12}}>
                    {
                        availableSolutions.map((d, i) => (
                            <CustomCardGridItem icon={d.icon} cardTitle={d.solutionTitle} routeLink={d.routeLink}
                                                roles={d.roles} key={i}/>
                        ))
                    }
                </Grid>
        </Container>
    )
}
export default CorporateStrategy





