import * as React from "react";
import Grid from "@mui/material/Grid";
import DatabaseSwitch from "./DatabaseSwitch/DatabaseSwitch";
import SearchInput from "./SearchInput/SearchInput";


function LiteratureSearchBar(props: { marginTop: number; }) {
    return (
        <Grid container sx={{alignItems: "center", justify: "center", justifyContent: "center", mt: props.marginTop }}
              columns={{xs: 12, sm: 12, md: 12}}>
            <Grid item xs={2}  >
                <DatabaseSwitch/>
            </Grid>
            <Grid item xs={10}  >
                <SearchInput/>
            </Grid>
        </Grid>
    )
}

export default LiteratureSearchBar
