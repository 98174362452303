import React from 'react';
import {useResetRecoilState} from "recoil";
import {manualSliderControlAtomFamily, sliderValuesAtomFamily} from "../../../../LeftNavigation/YearSlider/YearSliderState";
import {Badge} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";


function YearSliderFilter(props: { filterSetId: any; filter: any; }) {
    const resetManualSliderChange = useResetRecoilState(manualSliderControlAtomFamily(props.filterSetId))
    // const [sliderValues, setSliderValues] = useRecoilState(sliderValuesAtomFamily(props.filterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(props.filterSetId))

    // const correctSliderValues = useRecoilValue()

    const deactivateElementFromFilterSet = () => {
        resetManualSliderChange();
        resetSliderValues();

    }


    const SliderValues = () => {
        const filter = props.filter;
        return (
            <>
                <Badge key={'sliderValues'}
                       style={{marginRight: 5, cursor: 'pointer'}}
                >
                    {filter.sliderValues[0]} - {filter.sliderValues[1]}
                </Badge>

                <IconButton style={{height: 12, marginTop: 3, marginLeft: 2, cursor: "pointer"}} onClick={() => {
                    deactivateElementFromFilterSet();  // pass the facet, the id of filter
                }}>
                    <RemoveCircleIcon/>
                </IconButton>
            </>
        )
    }

    return (
        <SliderValues/>
    );
}

export default YearSliderFilter;
