import SummarizeIcon from '@mui/icons-material/Summarize';
import screenShotVisConfig from "./ScreenshotVis/ScreenshotVis";
import saveVisConfig from "./SaveVis/SaveVis";


const reportingConfig =
    {
        label: 'Reporting',
        icon: <SummarizeIcon />,
        onClick: () => console.log("Reporting click"),
        items: [
            screenShotVisConfig,
            saveVisConfig
        ]
    }


export default reportingConfig;