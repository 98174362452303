import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import {useKeycloak} from "@react-keycloak/web";
import {Link} from "react-router-dom";
import routes from "../../../../Routing/routes";

export default function AccountMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const keycloak = useKeycloak().keycloak
    let isLoggedIn = keycloak.authenticated;


    return (
        <>
            {
                isLoggedIn ? <><Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                        <Tooltip title="Account settings">
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{minHeight: 0, minWidth: 0, pt: 0, ml: "auto", mr: 0}}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{width: 26, height: 26}}>LS</Avatar>
                            </IconButton>
                        </Tooltip>
                    </Box>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem>
                                <Avatar/> Profile
                            </MenuItem>
                            <MenuItem>
                                <Avatar/> My Projects
                            </MenuItem>
                            <Divider/>
                            {/*
                                 <MenuItem>
                                <ListItemIcon>
                                    <PersonAdd fontSize="small"/>
                                </ListItemIcon>
                                Add another account
                            </MenuItem>
                            */}
                            <Link to={"settings"} style={{textDecoration: "none", color: "inherit"}}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <Settings fontSize="small"/>
                                    </ListItemIcon>
                                    Settings
                                </MenuItem>
                            </Link>
                            <MenuItem onClick={() => keycloak.logout()}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Logout
                            </MenuItem>
                        </Menu></> :

                    <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                        <Tooltip title="Login">
                            <IconButton
                                onClick={() => keycloak.login()}
                                size="small"
                                sx={{minHeight: 0, minWidth: 0, pt: 0, ml: "auto", mr: 0, color: "white"}}
                            >
                                <LoginIcon fontSize="small"/>
                            </IconButton>
                        </Tooltip>
                    </Box>
            }

        </>
    );
}