import * as React from 'react';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import Grid from "@mui/material/Grid";
import {SearchOutlined} from "@mui/icons-material";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import SpeedIcon from '@mui/icons-material/Speed';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import CustomCardGridItem from "../../../UI/CustomCardGridItem/CustomCardGridItem";

const availableSolutions = [
    {
        solutionTitle: "Data Analytics",
        routeLink: "data-analytics",
        roles: ["full_access_user"],
        icon: <AnalyticsOutlinedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
    {
        solutionTitle: "Corporate Strategy",
        routeLink: "corporate-strategy",
        roles: ["full_access_user"],
        icon: <CorporateFareIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
    {
        solutionTitle: "Literature Search",
        routeLink: "literature-search",
        roles: ["limited_access_user"],
        icon: <SearchOutlined color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
    {
        solutionTitle: "Collaborative Research",
        routeLink: "collaborative-research",
        roles: ["limited_access_user"],
        icon: <GroupsOutlinedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>

    },
    {
        solutionTitle: "APIs",
        routeLink: "apis",
        roles: [],
        icon: <StorageOutlinedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
    {
        solutionTitle: "Web Based Evaluations",
        routeLink: "web-based-evaluations",
        roles: [],
        icon: <SpeedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
    },
]

function Solutions() {
    return (
            <Grid container sx={{alignItems: "center", justifyContent: "center"}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                {
                    availableSolutions.map((d, i) => (
                        <CustomCardGridItem icon={d.icon} cardTitle={d.solutionTitle} routeLink={d.routeLink}
                                            roles={d.roles} key={i}/>
                    ))
                }
            </Grid>
    )
}

export default Solutions;
