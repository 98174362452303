// format data for chart:
import {genericSort} from "../../../utility/utility";
import {atom, selector, selectorFamily} from "recoil";
import {vissightsConfig} from "../zoomAndFilter/vissightsConfig";

const formatSearchHistoryData = (searchRequests: { [s: string]: unknown; } | ArrayLike<unknown>, amount: number | undefined) => {
    const keys = Object.keys(searchRequests);
    const values = Object.values(searchRequests);
    const formattedData: { amount: unknown; text: string; index: number; }[] = [];
    keys.forEach((d, i) => {
        formattedData.push({amount: values[i], text: keys[i], index: i});
    });
    const sortedData = formattedData.sort(genericSort('amount', 'desc'));
    return sortedData.slice(0, amount);
}

// Load topic search history data
export const fetchSearchHistorySelectorFamily = selectorFamily({
    key: 'searchHistorySelector',
    /**  db
     * @param db
     */
    get: db => async ({}) => {
        //get: async ({get}) => {
        //   const db = get(searchDatabase);
        try { //https://api.morpheus.fbmd.h-da.de/db/searchhistory?db=springer
            // @ts-ignore
            const searchHistoryUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/searchhistory?db=" + db; // .name;
            const searchHistory = await fetch(searchHistoryUrl);
            const searchHistoryData = await searchHistory.json();
            //console.log(searchHistoryData)
            return formatSearchHistoryData(searchHistoryData, 12);
        } catch (error) {
            throw error;
        }
    }// ,
    //effects_UNSTABLE: [persistAtom],
});

// call the fetchSearchHistory selector and store it as default value for the data atom
//export const searchHistoryDataAtom = atom({
//    key: "searchHistoryDataKey",
//    default: fetchSearchHistorySelectorFamily,
//});