import './metaNavigation.scss';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import AccountMenu from "./AccountMenu/AccountMenu";
import Container from '@mui/material/Container';
import ThemeSwitch from "./ThemeSwitch/ThemeSwitch";
import Logo from "../../Logo/Logo";
import {Link} from "react-router-dom";
import {displayMetaNavigationFullHeightAtom} from "./state/MetaNavigationState";
import {useRecoilState} from "recoil";
import CustomAppBar from "./CustomAppBar/CustomAppBar";

function MetaNavigation() {
    const [displayMetaNavigation, setDisplayMetaNavigation] = useRecoilState(displayMetaNavigationFullHeightAtom);

    return (
        <>
            <CustomAppBar/>
        </>
    )
}

export default MetaNavigation;

/*
 <AppBar position="fixed" color="primary" style={{height: displayMetaNavigation ? 40 : 5}} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Container maxWidth="xl">
                    <Toolbar variant="dense" color="primary" sx={{justifyContent: "space-between", color: "primary !important"}}>
                        <Link to={"/"}>
                            <Logo height={30}/>
                        </Link>
                        <div><ThemeSwitch/></div>
                        <AccountMenu/>
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar/>
 */