import {useRecoilState} from "recoil";
import {primaryColorAtom, secondaryColorAtom} from "../../../styles/themes/themes";
import {Box, Tab, Tabs} from '@mui/material';
import {SetStateAction, useState} from 'react';
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// @ts-ignore
import PrimaryColorPicker from 'mui-primary-color-picker';
import Grid from "@mui/material/Grid";

function Settings() {
    const [primaryColor, setPrimaryColor] = useRecoilState(primaryColorAtom);
    const [secondaryColor, setSecondaryColor] = useRecoilState(secondaryColorAtom);
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event: any, newTabIndex: SetStateAction<number>) => {
        setTabIndex(newTabIndex);
    };

    return (
        <>
            <Container maxWidth="xl">
                <h1>Settings</h1>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIndex} onChange={handleTabChange}>
                        <Tab label="Appearance"/>
                        <Tab label="Tab 2"/>
                        <Tab label="Tab 3"/>
                    </Tabs>
                </Box>
                <Box sx={{ padding: 2 }}>
                    {tabIndex === 0 && (
                        <>
                            <h1>Change Appearance of Theme</h1>
                            <Grid container>
                                <Grid item sx={{mr: 10}}>
                                    <Typography>Primary Color</Typography>
                                    <PrimaryColorPicker
                                        defaultColor={primaryColor}
                                        onChange={(color: any) => setPrimaryColor(color)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography>Secondary Color</Typography>
                                    <PrimaryColorPicker
                                        defaultColor={secondaryColor}
                                        onChange={(color: any) => setSecondaryColor(color)}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {tabIndex === 1 && (
                        <Box>
                            <Typography>The second tab</Typography>
                        </Box>
                    )}
                    {tabIndex === 2 && (
                        <Box>
                            <Typography>The third tab</Typography>
                        </Box>
                    )}
                </Box>
            </Container>
        </>
    );
}

export default Settings;
