import * as React from "react";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import RadarIcon from "@mui/icons-material/Radar";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import Grid from "@mui/material/Grid";
import CustomCardGridItem from "../../../UI/CustomCardGridItem/CustomCardGridItem";
import DashboardIcon from '@mui/icons-material/Dashboard';
function CorporateForesight() {

    const availableSolutions = [
        {
            solutionTitle: "Trend Monitoring",
            routeLink: "trend-monitoring",
            roles: ["full_access_user"],
            icon: <RadarIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
        },
        {
            solutionTitle: "Trend Detection",
            routeLink: "trend-detection",
            roles: ["full_access_user"],
            icon: <QueryStatsOutlinedIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
        },
        /*
        {
            solutionTitle: "Trend Dashboard",
            routeLink: "trend-dashboard",
            roles: ["full_access_user"],
            icon: <DashboardIcon color="primary" sx={{fontSize: "4em", marginLeft: "auto"}}/>
        } */
    ]

    return(
        <Container maxWidth="xl">
            <h1>Corporate Foresight</h1>
            <Divider/>
                <br/>
                <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}
                      columns={{xs: 4, sm: 8, md: 12}}>
                    {
                        availableSolutions.map((d, i) => (
                            <CustomCardGridItem icon={d.icon} cardTitle={d.solutionTitle} routeLink={d.routeLink}
                                                roles={d.roles} key={i}/>
                        ))
                    }
                </Grid>
        </Container>
    )
}
export default CorporateForesight



