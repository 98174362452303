import { styled } from '@mui/system';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

export const StyledAccordionSummary = styled(AccordionSummary)(({theme}) => ({
    minHeight: 35,
    maxHeight: 35,
    backgroundColor: theme.palette.grey.A100,
    '&.Mui-expanded': {
        minHeight: 35,
        maxHeight: 35,
        backgroundColor: theme.palette.primary.main,
    }
}));

export const StyledAccordionDetails = styled(AccordionDetails)(({theme}) => ({
    minHeight: 80,
    maxHeight: 100,
    paddingLeft: 6,
    paddingRight: 6,
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper
}));

export const StyledAccordionDetailsPOI = styled(AccordionDetails)(({theme}) => ({
    paddingLeft: 6,
    paddingRight: 6,
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper
}));