import React, {useRef, useEffect} from 'react';
import * as d3 from 'd3';
import {deviation} from 'd3-array';

const ForecastAccuracy = ({width, height, data, color, theme}) => {
    const ref = useRef();

    useEffect(() => {
        const svg = d3.select(ref.current);
        renderLinearProgress(svg, width, height, data, color, theme);
    }, [width, height, data, color, theme]);

    return (
        <svg
            ref={ref}
            width={width}
            height={height}
            //transform to center the svg in the paper
            transform={"translate(-5,0)"}

        />
    );
};

const renderLinearProgress = (svg, width, height, data, color, theme) => {
    svg.selectAll('*').remove();

    if (data !== undefined) {

        svg
            .append('rect')
            .datum(data)
            .attr('fill', "grey")
            .attr('stroke', "grey")
            .attr('stroke-width', 1)
            .attr('width', width)
            .attr('height', height);

        svg
            .append('rect')
            .datum(data)
            .attr('fill', color)
            .attr('stroke', color)
            .attr('stroke-width', 1)
            .attr('width', width - ( 100 - data))
            .attr('height', height)
            .attr("id", "circleBasicTooltip");

        //const tooltip = svg
        //    .append('div')
        //    .style('position', 'absolute')
        //    .style('background-color', theme.palette.background.paper)
        //    // .style('background-color', 'white')
        //    .style('border', '1px solid black')
        //    .style('padding', '5px')
        //    .style('display', 'none')
        //    .text("aasdasdasdasdasd");


        // Tooltip
        const tooltip = d3
            .select('body')
            .append('div')
            .style('position', 'absolute')
            .style('background-color', theme.palette.background.paper)
            // .style('background-color', 'white')
            .style('border', '1px solid black')
            .style('padding', '5px')
            .style('display', 'none');
        // Mouse events
        svg
            .on('mousemove', (event) => {

                    let tooltipHtml = `Forecast Accuracy: ${Math.round(data * 100) / 100}%`;
                    tooltip
                        .style('left', `${event.pageX + 10}px`)
                        .style('top', `${event.pageY + 10}px`)
                        .style('display', 'inline')
                        .html(tooltipHtml);

            })
            .on('mouseover', () => {
                //verticalLine.style('display', 'inline');
            })
            .on('mouseout', () => {
                //verticalLine.style('display', 'none');
                tooltip.style('display', 'none');
            });





    }
};

export default ForecastAccuracy;

