import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {
    manualSliderControlAtomFamily,
    sliderMinMaxValuesAtomFamily,
    sliderValuesAtomFamily,
    sliderValuesSelectorFamily
} from "./YearSliderState";
import {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import {activeFilterSetIdAtom, facetFiltersAtomFamily} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";
import {activePoiSelectorFamily} from "../LeftNavigationState";
import {recursiveDeepClone} from "../../../../../../utility/utility";
import {
    searchDatabaseAtom,
    searchTermSelector
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/search";


function valuetext(value: number): string {
    return `${value}`;
}


export default function YearSlider() {
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    const [sliderValues, setSliderValues] = useRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const [facetFilters, setFacetFilters] = useRecoilState(facetFiltersAtomFamily(activeFilterSetId));
    const sliderMinMax = useRecoilValue(sliderMinMaxValuesAtomFamily(activeFilterSetId));
    const resetSliderMinMax = useResetRecoilState(sliderMinMaxValuesAtomFamily(activeFilterSetId));

    const [usedManualSliderChange, setUsedManualSliderChange] = useRecoilState(manualSliderControlAtomFamily(activeFilterSetId));
    const sliderValuesOnChange = useRecoilValue(sliderValuesSelectorFamily(activeFilterSetId));
    const activePoiList = useRecoilValue(activePoiSelectorFamily(activeFilterSetId));
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId));
    const [tempSliderValues, setTempSliderValues] = useState(sliderValues);

    // to reset the slider values on specific events like search term change or db change
    const searchTerm = useRecoilValue(searchTermSelector);
    const currDb = useRecoilValue(searchDatabaseAtom);

    useEffect(() => {
        resetSliderMinMax();
        resetSliderValues();
        setTempSliderValues(sliderMinMax)

    },[searchTerm, currDb])



    const setNewSliderValues = (val:any, manual: boolean) => {
        /** Validate input **/
        // check if both numbers are 4 digits long.
        if (val[0].toString().length === 4 && val[1].toString().length === 4) {
            setSliderValues(val);
            // allow using slider manually
            setUsedManualSliderChange(manual);
            let clone = recursiveDeepClone(facetFilters);
            // remove normal facet filters for Facet Years on using slider
            if (clone !== undefined) {
                clone = clone.filter((d: { facet: string; }) => d.facet !== 'Years');
                setFacetFilters(clone)
            }
        }
    }



    useEffect(() => {
        // @ts-ignore
        if (facetFilters.length > 0 || activePoiList.length > 0) {
            resetSliderValues();
            // @ts-ignore
            setSliderValues([Number(sliderValuesOnChange[0]), Number(sliderValuesOnChange[1])])
            // setNewSliderValues([Number(sliderValuesOnChange[0]), Number(sliderValuesOnChange[1])], false)
        } else if (!usedManualSliderChange) {
            resetSliderValues();
        }
    }, [activePoiList, facetFilters])

    const handleManualChange = (newValue: number | number[]) => {
        setNewSliderValues(newValue as number[], true);
        setTempSliderValues(newValue as number[])
    };


    const handleSetInputFieldFromValueChange = (newValue: number | number[]) => {
        // @ts-ignore
        if(newValue[0] >= sliderMinMax[0] && newValue[0] <= sliderValues[1]){
            setNewSliderValues(newValue as number[], true);
            setTempSliderValues(newValue as number[])
        }
    }

    const handleSetInputFieldToValueChange = (newValue: number | number[]) => {
        // @ts-ignore
        if(newValue[1] <= sliderMinMax[1]  && newValue[1] >= sliderValues[0]){
            setNewSliderValues(newValue as number[], true);
            setTempSliderValues(newValue as number[])
        }
    }

    return (
        <>
            <Box sx={{ width: 200}}>
                <Slider
                    getAriaLabel={() => 'Year range change'}
                    value={tempSliderValues}
                    // @ts-ignore
                    onChange={(e) => setTempSliderValues(e.target.value)}
                    onChangeCommitted={() => setNewSliderValues(tempSliderValues, true)}
                    getAriaValueText={valuetext}
                    // @ts-ignore
                    min={sliderMinMax[0]}
                    // @ts-ignore
                    max={sliderMinMax[1]}
                />
            </Box>
            <TextField
                id="min-year"
                label="min-year"
                type="number"
                sx={{width: 60, float: "left"}}
                // @ts-ignore
                value={sliderValues[0]}
                // @ts-ignore
                onChange={(e) => handleSetInputFieldFromValueChange([e.target.value, sliderValues[1]])}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
            />
            <TextField
                id="max-year"
                label="max-year"
                type="number"
                sx={{width: 60, float: "right", mr: 2}}
                // @ts-ignore
                value={sliderValues[1]}
                // @ts-ignore
                onChange={(e) => handleSetInputFieldToValueChange([sliderValues[0], e.target.value])}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
            />
        </>
    );
}