export const genericSort = (key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }
        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];
        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    }
}

export const shortenTextForLegend = (text) => {
    return text.length <= 16 ? text : (text.slice(0, 11).toString() + '...');
}

export const shortenText = (text) => {
    return text.length <= 8 ? text : (text.slice(0, 8).toString() + '...');
}

export const shortenTextForLegendFullContent = (text, value) => {
    if(text !== undefined) {
        const fullStringLength = (text + value).length;
        if (fullStringLength <= 18) {
            return text;
        } else {
            return text.slice(0, 12).toString() + '...';
        }
        // return fullStringLength.length <= 22 ? text : ();
    } else {
        return '';
    }

}

// a method that copys literally everything :)
export const recursiveDeepClone = (inObject) => {
    let outObject, value, key
    if (typeof inObject !== 'object' || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}
    for (key in inObject) {
        value = inObject[key]
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = recursiveDeepClone(value)
    }
    return outObject
};


// This function takes the previously used colors and calculates a new random number whichs hue value is +X / -X away from others
export function calculateRandomHueValue(data, minColorDifference) {
    // Store all already used colors inside one array.
    const allTakenColors = [];
    // if(this.data !== undefined){
    // TODO: Habe hier erstmal zweite bedingung gestellt.. da wir jetzt unendlich viele pois haben können, theoretisch...
    if (data.length > 0 && data.length < 5) {
        data.map((d) => allTakenColors.push(Number(d.color)));
    }
    // if it is the first color we just return a random value.
    else {
        return Math.random() * 360;
    }
    // The while loop generates randomNumbers until we find one number which is different enough.
    let flag = true;
    while (flag) {
        // generate a random number 0-359
        let randomNumber = Math.random() * 360;
        let diffFitsNot = true;
        const diffArray = [];
        const diffFitsNotArray = [];
        // randwerte werden errechnet und einfach in das diffArray reingeballert...
        const upperBoundary = 360 - minColorDifference;
        const lowerBoundary = minColorDifference;
        if (randomNumber > upperBoundary || randomNumber < lowerBoundary) {
            diffArray.push(360 - randomNumber);
        }
        // calculate for each color its absolute difference to the new value (there is no positive or negative values for absolute numbers...)
        allTakenColors.forEach((d) => diffArray.push(Math.round((Math.abs(randomNumber - d)))));

        diffArray.forEach((d) => {
            // check for each value if its smaller than the minimum amount of difference.
            diffFitsNot = d <= minColorDifference;
            // create a new array with true or false values, dependant on if there is
            diffFitsNotArray.push(diffFitsNot);
        });

        // //circlepack(diffFitsNotArray);
        // if !!not!! all differences where smaller than the minColorDifference than run again
        if (diffFitsNotArray.includes(true)) {
            randomNumber = Math.round(Math.random() * 360);
        }
        // else stop and return the new random number which is at least the amount of minColorDifference away from each other value already picked
        else {
            flag = false;
            return Math.round(randomNumber);
        }
    }
    // }

}


export const calculateSignalStrengthOld = (d, totalSearchAnswerAmount) => {
    const percentage = (d.amount / totalSearchAnswerAmount) * 100;
    if (percentage > 8) {
        return 2;
    } else if (percentage > 1) {
        return 1;
    } else {
        return 0;
    }
}

export const calculateSignalStrengthWithTotalAmount = (d, total) => {
    const percentage = (d.amount / total) * 100;
    if (percentage > 6) {
        return 2;
    } else if (percentage > 1) {
        return 1;
    }
}

export const calculateSignalStrengthWithAverageOfAllAmounts = (d, total) => {
    const average = total / 100;
    if (d.amount >= average) {
        return 2;
    } else if (d.amount < average) {
        return 1;
    } else {
        return 1;
    }
}