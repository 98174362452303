import {atom, atomFamily, selector} from "recoil";
import keycloak from "../../../dataProvider/authentication/keycloak";

export const activeAggregationAtomFamily = atomFamily({
    key: "activeAggregationAtomFamilyKey",
    default: (id) => 'growth'
});

export const useNormalizationAtomFamily = atomFamily({
    key: "useNormalizationAtomFamily-Key",
    default: (id) => true
});


export const activeDatasetAtomFamily = atomFamily({
    key: "activeDatasetAtomFamilyKey",
    default: (id) => 'searchHistory'
});

export const activeFacetAtomFamily = atomFamily({
    key: "activeFacetAtomFamily-Key",
    default: (id) => 'Topics' // FACET.TOPIC
});





export const windowInnerWidthSelector = selector({
    key: "windowInnerWidthSelector-Key",
    get: () => 0
});

export const windowInnerHeightSelector = selector({
    key: "windowInnerHeightSelector-Key",
    get: () => 0
});

export const singleVisualizationSize = atom({
    key: "singleVisualizationSize-Key",
    default: [windowInnerWidthSelector, windowInnerHeightSelector]
});
