import {createTheme} from '@mui/material/styles';
import {atom, selector} from "recoil";
import {recoilPersist} from "recoil-persist";
import {grey} from "@mui/material/colors";
const {persistAtom} = recoilPersist()


export const primaryColorAtom = atom({
    key: "primaryColorAtom-Key",
    default: '#3a35af',
    effects_UNSTABLE: [persistAtom]
});

export const secondaryColorAtom = atom({
    key: "secondaryColorAtom-Key",
    default: '#ffaa11',
    effects_UNSTABLE: [persistAtom]
});

export const currentThemeAtom = atom({
    key: "currentThemeAtom-Key",
    default: 'light',
    effects_UNSTABLE: [persistAtom],
});


// here we load all data for the search
export const currentThemeSelector = selector({
    key: "currentThemeSelector-Key",
    get: ({get}) => {
        const currThemeMode = get(currentThemeAtom);
        const primaryColor = get(primaryColorAtom);
        const secondaryColor = get(secondaryColorAtom);
        let theme;
        if(currThemeMode === "light"){
            theme = createTheme({
                palette: {
                    mode: currThemeMode,
                    primary: {main: primaryColor},
                    secondary: {main: secondaryColor},
                    contrastThreshold: 4.5,
                },
            });
        } else {
            theme = createTheme({
                palette: {
                    mode: currThemeMode,
                    contrastThreshold: 4.5,
                    background: {
                     // default: grey[900],
                    //   paper: grey[900],
                    },
                },
            });
        }
        return theme;
    }
});








