import React, {useEffect} from "react";
import WordCloudChart from "./Wordcloud";
import {wordCloudChartDataAtomFamily} from "./state/WordCloudState";
import {useRecoilState, useRecoilValue} from "recoil";
import keycloak from "../../../../dataProvider/authentication/keycloak";
import {activeDatasetAtomFamily} from "../global-visualization-state";
import {recursiveDeepClone} from "../../../../utility/utility";
import {monitoringDataAtom} from "../../../../dataProvider/userDataModel/UserDataModel";
import VisualizationTopControls from "../Controls/TopControls/VisualizationTopControls";
import {windowHeightAtom, windowWidthAtom} from "../../../../config/sizings/sizings";


function WordCloudContainer(props: { id: string; topControls: any; deleteContainer: any;}) {
    const componentId = props.id;
    // const setModuleToDisplay = props.setModuleToDisplay;
    // const activeTopControl = useRecoilValue(activeDatasetAtomFamily(componentId));
    const [activeDataset, setActiveDataset] = useRecoilState(activeDatasetAtomFamily(componentId))
    // const setSearchTerm = props.setSearchTerm;
    const results = useRecoilValue(wordCloudChartDataAtomFamily(componentId));
    const monitoringData: any = useRecoilValue(monitoringDataAtom);
    const data = recursiveDeepClone(results); //.filter((d)  => d.text.length < 60)
    const [windowWidth, setWindowWidth] = useRecoilState(windowWidthAtom);
    const [windowHeight, setWindowHeight] = useRecoilState(windowHeightAtom);

    useEffect(() => {
        const handleResize = () => {
            // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
            setWindowWidth(window.innerWidth - 50);
            setWindowHeight(window.innerHeight - 100);
        }
        window.addEventListener('resize', handleResize)
    })


    useEffect(() => {
        if(keycloak.authenticated &&  monitoringData.length >= 1 && activeDataset !== "monitoring"){
            setActiveDataset("monitoring");
        }
    },[])

    /*
    <WordCloudTopControls topControlConfig={props.topControls}
                                  deleteContainer={props.deleteContainer}
                                  id={props.id}
            />

             <div style={{width: props.containerDimensions[0]}}>
               </div>
     */

    // handle global resize event
    // let width = window.innerWidth - 50;
    // let height = window.innerHeight - 100;


    return (
        <div>
                <VisualizationTopControls/>
                <WordCloudChart
                    id={props.id}
                    results={data}
                    width={windowWidth}
                    height={windowHeight}
                    activeTopControl={activeDataset}
                    // setModuleToDisplay={setModuleToDisplay}
                    // setSearchTerm={setSearchTerm}
                    // setSearchTermAndRerouteToZoomAndFilter={props.setSearchTermAndRerouteToZoomAndFilter}
                />
        </div>
    );
}

export default WordCloudContainer;