import {atom, atomFamily, selector, selectorFamily} from "recoil";
import DATA from "./data";
import * as _ from 'lodash';
import {searchDataAtom, searchDatabaseAtom, searchDataSelector, searchTermSelector} from "../search";
import FACET from "../../facet-extraction-utility";
import {topicsAtom} from "../../../general/topics/topics";
import {
    sliderMinMaxValuesAtomFamily,
    sliderValuesAtomFamily
} from "../../../../../components/Modules/LiteratureSearch/Navigations/LeftNavigation/YearSlider/YearSliderState";
import {
    poiAtomFamily,
    poiWithDataSelectorFamily
} from "../../../../../components/Modules/LiteratureSearch/Navigations/LeftNavigation/LeftNavigationState";
import {vissightsConfig} from "../../vissightsConfig";


// applies all filters from all filter sets to the data -> is used by the visualizations.
export const filteredDataSelector = selector({
    key: "filteredDataSelector-Key",
    get: async ({get}) => {
        // const searchData = get(searchDataSelector);
        console.log("filteredDataSelector")
        // topic names.
        const filterSets = get(filterSetsAtom);
        const rawData = get(searchDataAtom);
        // we will have to iterate over all filterSet ids and combine results.
        let combinedData = [];
        // aggregate facet filters for each set.
        await filterSets.forEach((d) => {
            const facetFilters = get(facetFiltersAtomFamily(d));
            const poiFilters = get(activePoisWithDataSelectorFamily(d));
            const sliderValues = get(sliderValuesAtomFamily(d));
            const sliderMinMax = get(sliderMinMaxValuesAtomFamily(d))
            const isSliderActive = sliderMinMax[0] !== sliderValues[0] || sliderMinMax[1] !== sliderValues[1]
            // we have to check if any filter was already applied. otherwise it would be always the full data.
            if(facetFilters.length > 0 || poiFilters.length > 0 || isSliderActive ){ //
                const newData = applyFilter(rawData, facetFilters, poiFilters, sliderValues)
                combinedData.push(newData)
                // console.log(combinedData)
            }
        })
        // array that holds all data but also duplicates
        const flattenedData = _.flatten(combinedData);
        // removes duplicates from the array.
        let uniqueData = _.uniqBy(flattenedData, (e) => {
            return e.key;
        });
        if (uniqueData.length <= 0) {
            uniqueData = rawData;
        }
        return uniqueData; // applyFilter(rawData, facetFilters, poiFilters, sliderValues);
    }
});

// data given to this selector will be returned in facet form (can be used on all or filtered data)
export const facetedFilteredDataSelector = selector({
    key: "facetedFilteredDataSelector-Key",
    get: ({get}) => {
        const topics = get(topicsAtom);
        const filteredSearchData = get(filteredDataSelector);
        return FACET.extractAllFacets(filteredSearchData, topics);
    }
});

// we get the available Facets from the filtered data.
export const facetedFilteredDataAvailableFacetsSelector = selector({
    key: "facetedFilteredDataAvailableFacetsSelector-Key",
    get: ({get}) => {
        //if(get(moduleToDisplayAtom) !== "overview"){
            let facetedFilteredData = get(facetedFilteredDataSelector);
            let availableFacets = [];
            // iterate over the data and check which Facet is not empty.
            for (let d in facetedFilteredData) {
                if( facetedFilteredData[d].length > 0) {
                    availableFacets.push(d);
                }
            }
            return availableFacets;
     //   } else {
     //       return []
     //   }
    }
});



export const amountFilteredDataSelector = selector({
    key: "amountFilteredDataSelectorKey",
    get: ({get}) => {
        const filteredData = get(filteredDataSelector);
        return filteredData.length;
    }
});

// here we preprocess the data. lazy (no async)
export const preProcessedFilteredSearchDataSelector = selector({
    key: "preProcessedFilteredSearchDataSelectorKey",
    get: ({get}) => {
        // const filteredData = await get(filteredDataSelector);
        // create facet data from the filtered data.
        return get(facetedFilteredDataSelector);
    }
});


export const activeFilterSetIdAtom = atom({
    key: "activeFilterSetIdAtom-Key",
    default: 'initialId',
});

export const filterSetsAtom = atom({
    key: "filterSetsAtom-Key",
    default: ['initialId'],
});

export const filterSetsForSubNavigationSelector = selector({
    key: "filterSetSelector-Key",
    get: ({get}) => {
        const filterSets = get(filterSetsAtom);
        // we will have to iterate over all filterSet ids and combine results.
        // aggregate facet filters for each set.
        const formattedFilterSets = [];
        filterSets.forEach((d) => {
            const facetFilters = get(facetFiltersAtomFamily(d));
            const poiFilters = get(activePoisWithDataSelectorFamily(d));
           // const sliderValues = get(sliderValuesAtomFamily(d));
           // const isSliderActive = get(isSliderActiveSelectorFamily(d));
            // const manualSliderManipulation = get(manualSliderControlAtomFamily(d))
            formattedFilterSets.push({
                filterSetId: d,
                facetFilters: facetFilters,
                poiFilters: poiFilters,
                // sliderValues: sliderValues,
                // isSliderActive: isSliderActive,
                // manualSliderManipulation: manualSliderManipulation
            })
        })
        return formattedFilterSets;
    }
});


export const facetFiltersAtomFamily = atomFamily({
    key: "facetFiltersAtomFamily-Key",
    default: [],
});



export const poisWithDataAtomFamily = atomFamily({
    key: "poisWithDataAtomFamily-Key",
    default: (id) => poiWithDataSelectorFamily(id)
});

export const poisWithFilteredDataSelectorFamily = selectorFamily({
    key: "poisWithFilteredDataAtomFamily-Key",
    get: (id) => async ({get}) => {
       // const activeFilterSetId = get(activeFilterSetIdAtom);
        const filterSets =  get(filterSetsAtom);
        const rawData = await get(searchDataSelector);
        const poiWithData = get(poisWithDataAtomFamily(id));
        const clone = _.cloneDeep(poiWithData)
        clone.map((d) => {
            const filteredData = _applyFilterPOI(rawData, d)
            let combinedData = [];
            // aggregate facet filters for each set.
            filterSets.forEach((d) => {
                const facetFilters = get(facetFiltersAtomFamily(d));
                const poiFilters = poiWithData.filter((d) => d.active === true); // get(activePoiFiltersSelectorFamily(d));
                const sliderValues = get(sliderValuesAtomFamily(d));
                const sliderMinMax = get(sliderMinMaxValuesAtomFamily(d))
                const isSliderActive = sliderMinMax[0] !== sliderValues[0] || sliderMinMax[1] !== sliderValues[1]
               // const isSliderActive = get(isSliderActiveSelectorFamily(d));
                // we have to check if any filter was already applied. otherwise it would be always the full data.
                if (facetFilters.length > 0 || clone.length > 0 || isSliderActive) {
                    const newData = applyFilter(filteredData, facetFilters, poiFilters, sliderValues)
                    combinedData.push(newData)
                }
            })
            // array that holds all data but also duplicates
            const flattenedData = _.flatten(combinedData);
            // removes duplicates from the array.
            d.data = _.uniqBy(flattenedData, (e) => {
                return e.key;
            });
            return d;
            // return uniqueData; // applyFilter(rawData, facetFilters, poiFilters, sliderValues);
        })
        return clone;
    }
});

// only used to get the active ones. Do not get data to prevent circular dependencys
export const activePoiFiltersSelectorFamily = selectorFamily({
    key: "activePoiFiltersSelectorFamily-Key",
    get: (id) => ({get}) => {
        const activePois = get(poiAtomFamily(id));
        return activePois.filter((d) => d.active === true);
    },
});

export const activePoisWithDataSelectorFamily = selectorFamily({
    key: "activePoisWithDataSelectorFamily-Key",
    get: (id) => ({get}) => {
        const activePois = get(poisWithDataAtomFamily(id));
        return activePois.filter((d) => d.active === true);
    },
});

// search for only keys concerning the current search + the new poi -> intersection (AND)
export const poiDataSelectorFamily = selectorFamily({
    key: "poiDataSelectorFamily-Key",
    get:  (poi) => async ({get}) => {
        const db = get(searchDatabaseAtom);
        const query = get(searchTermSelector);
        let url = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/searchonlykeys?db=" + db.name + "&q=" + query + '%20AND%20' + poi;
        const resp = await fetch(url);
        return resp.json();
    }
});

export const latestRemovedPoiAtomFamily = atomFamily({
    key: "latestRemovedPoiAtomFamily-Key",
    default: '-1'
});

// gloabl exports of filter functions!
export const applyFilter = (data, filter, filterPOI, sliderValues) => {
    // apply poi filter
    filterPOI.forEach((poi) => {
        data = _applyFilterPOI(data, poi);
    });
    const minYear = sliderValues[0];
    const maxYear = sliderValues[1];

    if (data !== null && data !== undefined && data.length > 0) {
        data = data.filter((d) => {
            return d.year <= maxYear && d.year >= minYear;
        });
    }
    // apply facet filter
    for (const facet in filter) {
        if (!filter.hasOwnProperty(facet)) continue;
        data = _applyFilterFacet(data, filter[facet].facet, filter[facet].values);
    }
    return data;
}


export const _applyFilterFacet = (data, facet, values) => {
    if (values.length === 0) return data;
    return data.filter((entry) => {
        let amount = 0;
        FACET.eachFacet(facet, entry, (id) => {
            if (values.indexOf(id) !== -1) amount++;
        });
        return amount === values.length;
    });
}


export const _applyFilterPOI = (data, poi) => {
    // no data? return!
    if (!poi.data) return data;
    // if we have data we apply the poi filters.
    return data.filter((entry) => poi.data.find((e) => e === DATA.Key(entry)));
}
