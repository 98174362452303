import LibraryAddIcon from '@mui/icons-material/LibraryAdd';


const saveVisConfig =
    {
        label: 'Save Vis',
        icon: <LibraryAddIcon />,
        onClick: () => console.log("save vis"),
    }

export default saveVisConfig;



/*
<Row>
    <Col xs={12}>
        <div className="grid-item-container-top-control">
            <div className="float-left" style={{ display: 'flex'}}>
                <Dropdown position="right"
                          title={<FontAwesomeIcon style={{width: 20, height: 20}} icon={faCog}/>}
                >
                    <Dropdown.Item onClick={() => createVisualizationScreenshot()}>
                        <FontAwesomeIcon style={{marginRight: 10, width: 20, height: 20}}
                                         icon={faCamera}/> Screenshot Chart
                    </Dropdown.Item>
                </Dropdown>

                <div style={{marginLeft: 15, marginTop: 6}}>
                    {dataSets.map((d) =>
                        <Badge key={d} style={{marginRight: 5, cursor: 'pointer'}}
                               variant={d === activeDataset ? "primary" : "light"}
                               onClick={(e) => {
                                   changeDataSetToDisplay(d);
                               }}>
                            {d}
                        </Badge>
                    )}
                    {activeDataset === "monitoring" ? <FontAwesomeIcon style={{marginLeft: 15, cursor: "pointer"}}
                                                                       icon={faPlusCircle} onClick={() => setDisplayCreateNewMonitoringItemModal(true)}/> : null}
                </div>
            </div>

            {displayCreateNewMonitoringItemModal ? <CreateNewMonitoringItemModal/> : null}

            <Button type="button" className="float-right" variant="light" value="Search" onClick={(e) => {
                e.preventDefault();
                props.deleteContainer(props.id);
            }}>X</Button>
        </div>
    </Col>
</Row>
 */