import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import {useRecoilState} from "recoil";
import {displayLeftNavigationAtom} from "../LeftNavigation/LeftNavigationState";
import Grid from "@mui/material/Grid";
import SearchBar from "./SearchBar";

function MainNavigation() {
    const [open, setOpen] = useRecoilState(displayLeftNavigationAtom);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    return (
        <AppBar position="fixed" color="default" style={{height: 80, marginTop: 40}} sx={{ zIndex: (theme) => theme.zIndex.drawer + 2}}>
                <Toolbar variant="dense" sx={{justifyContent: "space-between"}}>
                    <Grid container sx={{alignItems: "center", justify: "center", justifyContent: "center"}}
                          columns={{xs: 12, sm: 12, md: 12}}>
                        <Grid item xs={1}>
                            <IconButton
                                color="success"
                                aria-label="open drawer"
                                onClick={handleDrawerToggle}
                                edge="start"
                                sx={{ mr: 2 }} /* ...(open && { display: 'none' }) */
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs={3}/>
                        <Grid item xs={5}>
                            <SearchBar/>
                        </Grid>
                        <Grid item xs={3}/>
                    </Grid>
                </Toolbar>
        </AppBar>

    )
}

export default MainNavigation