import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Grid";
import {useRecoilState} from "recoil";
import {useEffect, useState} from "react";
import DatabaseSwitch from "../../SimpleLiteratureSearch/LiteratureSearchBar/DatabaseSwitch/DatabaseSwitch";
import {
    searchStringAtom,
    startSearchAtom
} from "../../../../../dataProvider/trend-analytics/zoomAndFilter/search/search";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    cursor: "pointer",
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));



function SearchBar() {
    const [searchString, setSearchString] = useRecoilState(searchStringAtom);

    const [tempSearchString, setTempSearchString] = useState(searchString || "")

    const [startSearch, setStartSearch] = useRecoilState(startSearchAtom);

    function handleSubmit(event: any) {
        event.preventDefault();
        setSearchString(tempSearchString);
        setStartSearch(true);
    }

    // reset start searching so it only fires on search button click...
    useEffect(() => {
        // setStartSearch(false);
    },[startSearch])


    return (
        <Grid container sx={{alignItems: "center", justify: "center", justifyContent: "center"}}
              columns={{xs: 12, sm: 12, md: 12}}>
            <Grid item xs={2}>
                <DatabaseSwitch/>
            </Grid>
            <Grid item xs={10}>
                <Search>
                    <SearchIconWrapper onClick={(e)=> handleSubmit(e)}>
                        <SearchIcon/>
                    </SearchIconWrapper>
                    <StyledInputBase
                        onChange={(e) =>  setTempSearchString(e.target.value)}
                        placeholder="Search…"
                        value={tempSearchString}
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Grid>
        </Grid>
    )
}

export default SearchBar
