// Load topics (names)
import {searchDatabaseAtom} from "../../zoomAndFilter/search/search";
import {vissightsConfig} from "../../zoomAndFilter/vissightsConfig";
import {atom, selector} from "recoil";

export const fetchTopics = selector({
    key: 'fetchTopicsSelector',
    // @ts-ignore
    get: async ({get}) => {
        const db = get(searchDatabaseAtom);
        console.log(db)
        try {
            const topicNamesUrl = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/topics?db=" + db.name;
            const topicData = await fetch(topicNamesUrl)
            return topicData.json();
        } catch (error) {
            throw error;
        }
    }
});

export const topicsAtom = atom({
    key: "topicsKey",
    default: fetchTopics,
});