import Container from "@mui/material/Container";
import Grid2 from "@mui/material/Unstable_Grid2";
import Logo from "../../../UI/Logo/Logo";

function Header() {
    return(
        <div>
            <Container maxWidth="xl">
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid2 xs={3}>
                        <Logo height={240}/>
                    </Grid2>
                    <Grid2 xs={9} sx={{pt:8}}>
                        <h1 style={{fontSize: "3em"}}>SEMAVIS - Visual Analytics Solutions</h1>
                    </Grid2>
                </Grid2>
                <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid2 xs={6}>
                        <h2>We offer various Visual Analytics solutions accross several application domains.</h2>
                    </Grid2>
                    <Grid2 xs={6}>
                    </Grid2>
                </Grid2>
            </Container>
        </div>
    )
}
export default Header;