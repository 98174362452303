import React from "react";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {atom, useRecoilState} from "recoil";


export interface SnackBarStateInterface {
    open: boolean,
    message: string,
    vertical: "bottom" | "top";
    horizontal: "center" | "left" | "right";
    color: "inherit" | "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
    duration: number;
}

export const snackBarAtom = atom({
    key: "snackBarAtom-Key",
    default: {message: "Snacko-Lennys Snackbar :P", vertical: "bottom", horizontal: "right", color: "error", duration: 8000} as SnackBarStateInterface,
});


function CustomSnackBar() {
    const [snackBarState, setSnackBarState] = useRecoilState(snackBarAtom);

    const handleClick = () => {
        setSnackBarState((prevState) => ({
            ...prevState,
            open: true,
        }));
    };

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackBarState((prevState) => ({
            ...prevState,
            open: false,
        }));
        // setOpen(false);
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color={snackBarState.color || "inherit"}
                onClick={handleClose}
            >
                <CloseIcon fontSize="small"/>
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={snackBarState.open}
                anchorOrigin={{vertical: snackBarState.vertical, horizontal: snackBarState.horizontal}}
                autoHideDuration={snackBarState.duration}
                onClose={handleClose}
                message={snackBarState.message}
                action={action}
            />
        </div>
    );
}

export default CustomSnackBar;

