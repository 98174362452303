import {LinearProgress, Paper, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import Sparkline from "../Sparkline";
import {useState} from "react";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {Tooltip} from "recharts";
import IconButton from "@mui/material/IconButton";
import ForecastAccuracy from "../ForecastAccuracy";

function SparklineContainer({data, forecastData, width, height, amountRows, index}) {
    const theme = useTheme();
    const forecastAccuracy = 100 - (data?.forecast?.evaluation !== undefined  ? data?.forecast?.evaluation[2].score?.MAPE : 0) * 10;

    let color;
    if(forecastAccuracy > 90){
        color = "#66bb6a"; //"success";
    } else if(forecastAccuracy > 80){
        color = "#ffa726"; // "warning";
    } else{
        color = "#f44336"; //"error";
    }


    return (
        <Grid item xs={3} key={data + '-' + index}>
            <Paper elevation={3} sx={{height: height}}>
                <Typography sx={{paddingLeft:2}}><b>{data.name}</b></Typography>
                {/*@ts-ignore*/}
                <Sparkline theme={theme} width={(width / amountRows)-30} height={90} color={"black"}
                           data={forecastData}></Sparkline>
                <Grid container justifyContent="flex-end">

                        <ForecastAccuracy data={forecastAccuracy} color={color.toString()} height={10} width={100} theme={theme}/>

                </Grid>

            </Paper>
        </Grid>
    )
}

export default SparklineContainer


/*

<LinearProgress
                            variant="determinate"
                            value={forecastAccuracy}
                            /*@ts-ignore*/
/*
color={color.toString()}
style={{float: "right", marginRight: 5, height: "10px", width: "25%"}}
/>
*/
/*


        <Paper elevation={3} sx={{height: 150, marginBottom: 2}}>
            <Typography><b>{name}</b></Typography>
            {/*@ts-ignore  (width / amountRows) - 30 */ /*}
<Sparkline width={width} height={height}
           color={"black"}
           data={data}>
</Sparkline>
<LinearProgress
    variant="determinate"
    value={Math.random() * 10 * 10}
    style={{
        float: "left",
        marginRight: 5,
        height: "10px",
        width: "25%"
    }}
/>
</Paper>
 */