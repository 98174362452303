import Logo from "../Logo/Logo";
import {LinearProgress} from "@mui/material";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

function LoadingSpinner() {
    return (
        <Container sx={{mt: "20%"}}>
            <Grid container direction="column" maxWidth="xl" alignItems="center" justifyContent="center">
                <Grid item>
                    <Logo height={40}/>
                </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="center">
                <Grid item>
                    <LinearProgress sx={{width: 50, height: 3}} color="secondary"/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default LoadingSpinner