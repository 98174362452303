import {atom, selector, selectorFamily} from "recoil";
import keycloak from "../authentication/keycloak";

export const fetchMonitoringItemsSelector = selector({
    key: 'fetchMonitoringItemsSelector-Key',
    get: async ({get}) => {
        let monitoringItemsList;

        const userId = keycloak?.tokenParsed?.sub;

        // localStorage.removeItem(keycloak.tokenParsed.preferred_username);
        if(localStorage.getItem(<string>userId)){
            // @ts-ignore
            monitoringItemsList = JSON.parse(localStorage.getItem(userId));
        } else {
            if (typeof userId === "string") {
                localStorage.setItem(userId, JSON.stringify([{monitoringItems: []}]))
            }
            // @ts-ignore
            monitoringItemsList = JSON.parse(localStorage.getItem(userId));
        }
        return monitoringItemsList[0].monitoringItems;
    } // ,
      //effects_UNSTABLE: [persistAtom],
});


export const monitoringDataAtom = atom({
    key: "monitoringDataAtom-Key",
    default: fetchMonitoringItemsSelector,
});


export const userProjectsAtom = atom({
    key: "userProjectsAtom-Key",
    default: [{id: "default", name: "default", active: true, data: []}]
});



export const currentActiveProjectSelector = selector({
    key: "currentActiveProjectSelector-Key",
    // @ts-ignore
    get:  db => async ({get}) => {
      const projects = get(userProjectsAtom);
      const currentActiveProject = projects.filter((d: { active: any; }) => d.active);
      return currentActiveProject;
    }
});




export const searchDataSelectorFamily = selectorFamily({
    key: 'searchDataSelectorFamily-Key',
    /**  db
     * @param db
     */
    get:  db => async ({get}) => {
        try {
           return []
        } catch (error) {
            throw error;
        }
    }
});
