import Container from "@mui/material/Container";
import * as React from "react";
import Grid from "@mui/material/Grid";
import PoiImage from "./PoiImage";


function IdentificationExample() {
// alignItems: "center", justify: "center",
    return (
        <Container maxWidth="xl">
            <Grid container sx={{ height: 770}} spacing={{xs: 2, md: 3}}
                  columns={{xs: 4, sm: 8, md: 12}}>
                <Grid item xs={6} style={{marginTop: 100}}>
                    <PoiImage height={600}></PoiImage>

                </Grid>
                <Grid item xs={6} style={{marginTop: 80, paddingLeft: 70}}>
                    <h2>Number of documents found:</h2>
                   <p><b>128</b> results for search term: ((deep* OR *neural*) AND (*network)) AND POI: "recurrent"</p>
                    <p><b>27</b> results for search term: ((deep* OR *neural*) AND (*network)) AND POI: "recurrent" AND POI: "convolutional" </p>
                    <p><b>2</b> results for search term: ((deep* OR *neural*) AND (*network)) AND POI: "recurrent" AND POI: "convolutional" AND POI: "artificial"</p>
                    <p><b>576</b> results for search term: ((deep* OR *neural*) AND (*network)) AND POI: "convolutional"</p>
                    <p><b>477</b> results for search term: ((deep* OR *neural*) AND (*network)) AND POI: "artificial"</p>
                    <p><b>16</b> results for search term: ((deep* OR *neural*) AND (*network)) AND POI: "artificial" AND POI: "recurrent"</p>
                </Grid>
            </Grid>
        </Container>
    )
}

export default IdentificationExample