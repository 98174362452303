import * as React from "react";
import {FormControl, InputLabel, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {useRecoilState} from "recoil";
import {availableDatabasesAtom, searchDatabaseAtom} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/search";
import { dbConfig } from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/vissightsConfig";



function DatabaseSwitch() {
    const [database, setDatabase] = useRecoilState(searchDatabaseAtom);
    const [availDbs, setAvailDbs] = useRecoilState(availableDatabasesAtom);

    const getCorrespondingDbFromString = (dbName: string) => {
        const returnValue = dbConfig.filter((d) => d.name === dbName);
        return returnValue[0];
    }
    const handleChange = (event: SelectChangeEvent) => {
        setDatabase(getCorrespondingDbFromString(event.target.value));
    };


    return (
        <>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-db">Database</InputLabel>
                <Select
                    labelId="select-standard-label"
                    id="simple-select-standard"
                    value={database.name}
                    onChange={handleChange}
                    label="Database"
                >
                    {
                        availDbs.map((d,i) => {
                            return(
                                <MenuItem value={d.name} key={d + String(i)}>
                                    {d.name}
                                </MenuItem>
                            )
                        })

                    }

                </Select>
            </FormControl>
        </>


    )
}

export default DatabaseSwitch

/*



 */