import {atomFamily, selector, selectorFamily} from "recoil";
import { recoilPersist } from 'recoil-persist'
const { persistAtom } = recoilPersist()

export const reportingContentAtom = atomFamily({
    key: "reportingContentAtom-Key",
    default: "",
    effects_UNSTABLE: [persistAtom]
});

export const singleVisualizationScreenshotsAtom = atomFamily({
    key: "singleVisualizationScreenshotsAtom-Key",
    default: [],
    effects_UNSTABLE: [persistAtom],
});

export const dashboardScreenshotsAtom = atomFamily({
    key: "dashboardScreenshotsAtom-Key",
    default: [],
    effects_UNSTABLE: [persistAtom],
});



// we filter for only active. We need this for filtersets and for updating inside PointsOfInterest.ts.
//export const createVisualizationScreenshotSelectorFamily = selector({
//    key: "createVisualizationScreenshotSelectorFamily-Key",
//    set: ({set}, newValue) =>
//        set(singleVisualizationScreenshotsAtom, []),
//
//});


