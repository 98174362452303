import {useState} from "react";
import {useRecoilState, useRecoilValue, useRecoilValueLoadable, useResetRecoilState, useSetRecoilState} from "recoil";
import {
    activeFilterSetIdAtom,
    latestRemovedPoiAtomFamily,
    poisWithFilteredDataSelectorFamily
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";
import {poiAtomFamily} from "../LeftNavigationState";
import {sliderValuesAtomFamily} from "../YearSlider/YearSliderState";
import {calculateRandomHueValue, recursiveDeepClone} from "../../../../../../utility/utility";
import {v4 as uuid4} from 'uuid';
import * as React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Accordion from "@mui/material/Accordion";
import {
    StyledAccordionDetailsPOI,
    StyledAccordionSummary
} from "../StyledComponents/StyledMuiAccordionElements/StyledAccordionElements";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import {FormControl, ListItem, ListItemText} from "@mui/material";


function PointsOfInterest(props: any) {
    // use local state for the current poi term...
    const [newPoiName, setNewPoiName] = useState('');
    const activeFilterSetId = useRecoilValue(activeFilterSetIdAtom);
    // use recoil state to keep track after the poi has been created.
    const [poiList, setPoiList] = useRecoilState(poiAtomFamily(activeFilterSetId));
    const poiWithFilteredData = useRecoilValueLoadable(poisWithFilteredDataSelectorFamily(activeFilterSetId));
    const setLatestRemovedPoi = useSetRecoilState(latestRemovedPoiAtomFamily(props.id));
    // Modal state.
    // const [displayItemNotSelectableModal, setDisplayItemNotSelectableModal] = useRecoilState(displayItemNotSelectableModalAtom);
    // const [displayPoiLengthModal, setDisplayPoiLengthModal] = useRecoilState(displayPoiMinLengthModalAtom);
    const resetSliderValues = useResetRecoilState(sliderValuesAtomFamily(activeFilterSetId));

    const addNewPoi = () => {
        if (newPoiName.length > 3) {
            const newUUID = uuid4();
            const clone = recursiveDeepClone(poiList)
            clone.push({id: newUUID, name: newPoiName, active: false, color: calculateRandomHueValue(clone, 35)});
            setPoiList(clone);
            setNewPoiName('');
        } else {
            // setDisplayPoiLengthModal(true);
            alert("setDisplayPoiLengthModal")
        }
    }

    const removePoi = (d: { id: any; name: string; }) => {
        const clone = recursiveDeepClone(poiList);
        const newPoiList = clone.filter((e: { id: any; }) => e.id !== d.id);
        setLatestRemovedPoi(d.name);
        setPoiList(newPoiList);
        resetSliderValues();
    }

    const togglePoiFilter = (data: { name: any; }) => {
        let clone = recursiveDeepClone(poiList);
        const poi = data.name;
        clone.map((d: { name: any; active: boolean; }) => {
            if (poi === d.name) {
                // we have to use the poi list which already has the data.
                const poiWithCurrAmount = poiWithFilteredData.contents.find((e: { name: string; }) => e.name === d.name);
                if (poiWithCurrAmount.data.length > 0) {
                    d.active = !d.active;
                } else {
                    // setDisplayItemNotSelectableModal(true);
                    alert("setDisplayItemNotSelectableModal")
                }
            }
            return d;
        });
        resetSliderValues()
        setPoiList(clone)
    }


    return (
        <>
            <Accordion defaultExpanded={poiList.length > 0}>
                <StyledAccordionSummary
                    sx={{m: 0, height: 50}}
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="poi-content"
                    id="poi-header"
                >
                    <Typography>Points of Interest</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetailsPOI>
                    {
                        /*
                         {poiWithFilteredData.va.map((d: any, i: number) => {
                        return(
                            <ListItem key={i} disablePadding>
                                <ListItemText>
                                   asd
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                         */

                        /*
                        <Row className={d.active ? 'active-poi mr-0 ml-0 pl-1' : "mr-0 ml-0 pl-1"}
                                     key={'wrapper-row' + i}
                                     style={{backgroundColor: d.active ? '#31beff' : 'white', cursor: 'pointer'}}>
                                    <Col xs={8} className={d.active ? 'active-poi pr-0 pl-0' : "pr-0 pl-0"}
                                         onClick={() => {
                                             togglePoiFilter(d)
                                         }}>
                                        {d.name}
                                    </Col>
                                    <Col xs={3} className={d.active ? 'active-poi pr-0 pl-0' : "pr-0 pl-0"}>
                                        {d.data.length}
                                    </Col>
                                    <Col xs={1} key={'poi-remove' + d.name}
                                         className={d.active ? 'active-poi pr-0 pl-0' : "pr-0 pl-0"} onClick={(e) => {
                                        removePoi(d);
                                    }}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Col>
                                </Row>
                         */

                        poiWithFilteredData.state === 'hasValue' ?
                            poiWithFilteredData.contents.map((d: any, i: number) =>
                                <ListItem key={i} disablePadding>
                                    <ListItemText onClick={() => togglePoiFilter(d)}>
                                       <span>
                                            {d.name}
                                       </span>
                                        <span style={{float: "right"}}>
                                            {d.amount}
                                       </span>
                                    </ListItemText>
                                </ListItem>
                            )
                            :
                            <div>loading...</div>
                    }
                </StyledAccordionDetailsPOI>
            </Accordion>


            <FormControl onSubmit={(e) => {e.preventDefault(); addNewPoi();}}>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': {ml: 2.5, width: '20ch',},
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        sx={{
                            mb: 2,
                            mt: 1
                        }}
                        id="pois"
                        label="Add POI"
                        onChange={(e) => {
                            setNewPoiName(e.target.value);
                        }}
                    />

                </Box>
            </FormControl>
        </>
    );

}

export default PointsOfInterest;


{

    /*
      <Row className='mr-0 ml-2'>
                <Col xs={10} className='pr-0 pl-0'>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addNewPoi();
                    }}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control size="sm" type="text" placeholder="Enter POI" value={newPoiName}
                                          onChange={(e) => {
                                              setNewPoiName(e.target.value);
                                          }}/>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={1} className='pr-0 pl-2'>
                    <FontAwesomeIcon style={{cursor: "pointer"}} icon={faPlus} onClick={() => {
                        addNewPoi();
                    }}/>
                </Col>
            </Row>
     */
}











/*
 <div style={{
            textAlign: "left",
            height: props.height,
            overflow: "auto"
        }}>
            {displayItemNotSelectableModal ? <ItemNotSelectableModal/> : null}
            {displayPoiLengthModal ? <PoiMinimumLengthModal/> : null}
            <Accordion key='accordion-poi' defaultActiveKey="poi-toggle">
                <Accordion.Toggle key="poi-toggle" as={NavLink} variant="link" eventKey="poi-toggle"
                                  className="facet-accordion-toggles">
                    Points of Interest
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="poi-toggle">
                    <div>
                        {
                            poiWithFilteredData.state === 'hasValue' ?
                                poiWithFilteredData.contents.map((d, i) =>
                                <Row className={d.active ? 'active-poi mr-0 ml-0 pl-1' : "mr-0 ml-0 pl-1"}
                                     key={'wrapper-row' + i}
                                     style={{backgroundColor: d.active ? '#31beff' : 'white', cursor: 'pointer'}}>
                                    <Col xs={8} className={d.active ? 'active-poi pr-0 pl-0' : "pr-0 pl-0"}
                                         onClick={() => {
                                             togglePoiFilter(d)
                                         }}>
                                        {d.name}
                                    </Col>
                                    <Col xs={3} className={d.active ? 'active-poi pr-0 pl-0' : "pr-0 pl-0"}>
                                        {d.data.length}
                                    </Col>
                                    <Col xs={1} key={'poi-remove' + d.name}
                                         className={d.active ? 'active-poi pr-0 pl-0' : "pr-0 pl-0"} onClick={(e) => {
                                        removePoi(d);
                                    }}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Col>
                                </Row>
                            )
                               :
                                <Row><Col>loading...</Col></Row>
                        }


                    </div>
                </Accordion.Collapse>
            </Accordion>
            <br/>
            <Row className='mr-0 ml-2'>
                <Col xs={10} className='pr-0 pl-0'>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addNewPoi();
                    }}>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Control size="sm" type="text" placeholder="Enter POI" value={newPoiName}
                                          onChange={(e) => {
                                              setNewPoiName(e.target.value);
                                          }}/>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={1} className='pr-0 pl-2'>
                    <FontAwesomeIcon style={{cursor: "pointer"}} icon={faPlus} onClick={() => {
                        addNewPoi();
                    }}/>
                </Col>
            </Row>
        </div>
 */
