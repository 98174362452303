// creates the new url for the second query.
import {atom, selectorFamily} from "recoil";
import {advancedSearchFieldsAtom, createQueryFilterUrlString} from "../advanced-search/advanced-search";
import {searchDatabaseAtom} from "../search";
import {vissightsConfig} from "../../vissightsConfig";
import FACET from "../../facet-extraction-utility";
import {genericSort} from "../../../../../utility/utility";
import {topicsAtom} from "../../../general/topics/topics";


// stuff we need for assisted Search
export const assistedSearchAtom = atom({
    key: "assistedSearchKey",
    default: false
});


// amount of phrases that are considered in assisted search
export const amountRelatedPhrasesToConsiderAtom = atom({
    key: "amountRelatedPhrasesToConsiderKey",
    // must be stored as a number array for the range slider...
    default: 3,
});

// amount of topics that are considered in assisted search (experimental)
export const amountRelatedTopicsToConsiderAtom = atom({
    key: "amountRelatedTopicsToConsiderKey",
    // must be stored as a number array for the range slider...
    default: 0,
});

// returns the query for assisted search
export const assistedSearchQuerySelectorFamily = selectorFamily({
    key: "assistedSearchQuerySelectorFamily-Key",
    get: searchTerm => async ({get}) => {
        const db = get(searchDatabaseAtom);
        // const searchTerm = get(searchTermAtom);
        // get the amounts of topics / phrases that should be used for advances search.
        const amountPhrases = get(amountRelatedPhrasesToConsiderAtom);
        const amountTopics = get(amountRelatedTopicsToConsiderAtom);
        const topics = get(topicsAtom);

        let query: any;
        if (searchTerm === '') {
            query = 'Visual Analytics';
        } else {
            query = searchTerm;
        }

        // advanced search fields act like a filter for specifying in which fields shall be searched in the db.
        const advancedSearchFields = get(advancedSearchFieldsAtom);

        // const dataModelToLoad = activeDataModel[0].value;

        const fieldsUrlStr = createQueryFilterUrlString(advancedSearchFields, '&search-field=');


        // let url = vissightsConfig.baseQuery + 'search' + vissightsConfig.apiVersion + '/publications/search?db=' + db.name + '&q=' + query + fieldsUrlStr;

        // url = encodeURI(url)

        // we load asynchronously only the temporal data. This will be returned first to be available to display the first chart
        let initialUrlQuery = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + "&include=topics" + "&limit=200" + "&q=" + query + fieldsUrlStr;
        let newUrlQuery;

        await fetch(initialUrlQuery).then(async (d) => {
            const newData = await d.json();
            console.log(newData, topics, query, amountPhrases, amountTopics)
            // return newUrlQuery = vissightsConfig.baseQuery + vissightsConfig.apiVersion + "/publications/search?db=" + db.name + includeUrlStr + ',key' + "&q=" + createQueryUrlForAssistedSearch(newData, topics, query, amountPhrases, amountTopics)  // + fieldsUrlStr;
            newUrlQuery = createQueryUrlForAssistedSearch(newData, topics, query, amountPhrases, amountTopics)
        });
        return newUrlQuery;
    }
});



export const createQueryUrlForAssistedSearch = (data: any, topics: unknown, query: any, amountPhrases: number, amountTopics: number) => {
    let topicfacets: any[] = [];
    // we only extract the topic facets
    // TODO: this should be done in the backend... Send a query that advances search is used. + topics + phrases amount to consider -> create new query with "OR" relations...
    topicfacets = FACET.extractFacet(data, FACET.TOPIC, topics);
    topicfacets = topicfacets.sort(genericSort('amount', 'desc'));
    // case we have no data
    if (topicfacets.length === 0) {
        return [];
    }
    // get dominant topic -> as its already sorted above we just use the first entry -> 0
    let dominantTopicId = topicfacets[0].id;

    // formulate new query with semantically similar phrases
    // @ts-ignore
    const correspondingPhrasesNGram = topics[dominantTopicId].phrase;
    // @ts-ignore
    const correspondingTopicsNGram = topics[dominantTopicId].word;

    let newQuery = query;

    for (let i = 0; i < amountPhrases; i++) {
        newQuery += ' OR ' + '\'' + correspondingPhrasesNGram[i][0] + '\'';
    }

    for (let i = 0; i < amountTopics; i++) {
        newQuery += ' OR ' + correspondingTopicsNGram[i][0];
    }

    return newQuery; // + fieldsUrlStr;
}