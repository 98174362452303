import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import {useRecoilState} from "recoil";
import {
    assistedSearchAtom
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/assisted-search/assisted-search";
import {
    advancedSearchFieldsAtom, includeSelectionAtom
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/advanced-search/advanced-search";
import {
    firstLazyAmountLoadedAtom,
    initialAmountLoadedAtom,
    maximumAmountLoadedAtom, useLazyLoadingAtom
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/lazy-loading/lazy-loading";
import {Container, Switch, Typography} from "@mui/material";
import {recursiveDeepClone} from "../../../../../../utility/utility";
import Grid2 from "@mui/material/Unstable_Grid2";

export default function SearchSettings() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [assistedSearch, setAssistedSearch] = useRecoilState(assistedSearchAtom);
    const [useLazyLoading, setUseLazyLoading] = useRecoilState(useLazyLoadingAtom);
    // const [considerRelatedPhrases, setConsiderRelatedPhrasesAtom] = useRecoilState(considerRelatedPhrasesAtom);
    // const [amountRelatedPhrases, setAmountRelatedPhrasesAtom] = useRecoilState(amountRelatedPhrasesToConsiderAtom);
    // const [amountRelatedTopics, setAmountRelatedTopicsAtom] = useRecoilState(amountRelatedTopicsToConsiderAtom);
    const [advancedSearchFields, setAdvancedSearchFields] = useRecoilState(advancedSearchFieldsAtom);
    const [initialAmountLoaded, setInitialAmountLoaded] = useRecoilState(initialAmountLoadedAtom);
    const [firstLazyAmountLoaded, setFirstLazyAmountLoaded] = useRecoilState(firstLazyAmountLoadedAtom);
    const [maximumAmountLoaded, setMaximumAmountLoaded] = useRecoilState(maximumAmountLoadedAtom);
    // const [specifiedFacetsFields, setSpecifiedFacetsFields] = useRecoilState(specifiedFacetsAtom);
    // we use local state to prevent data loading before clicking the search button in the modal.
    const [includeSelection, setIncludeSelection] = useRecoilState(includeSelectionAtom);


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const AssistedSearch = () => (
        <Typography>
            <Switch checked={assistedSearch} onChange={() => setAssistedSearch(!assistedSearch)}/>
            <span style={{paddingTop: 4}}><b>Assisted Search</b></span>
        </Typography>
    )

    const changeAdvancesSearchFieldsByNameVal = (fieldName: any) => {
        // clone the existing value
        let clone = recursiveDeepClone(advancedSearchFields);
        clone.map((d: any) => {
            if (d.name === fieldName) {
                return d.value = !d.value;
            } else return d;
        })
        setAdvancedSearchFields(clone)
    }

    const AdvancedSearch = () => (
        <>
            <Grid2 container sx={{maxWidth: 300}}>
                <Grid2 xs={12}>
                    <Typography>
                        <p><b>Advanced Search</b></p>
                    </Typography>
                </Grid2>

                {
                    advancedSearchFields.map((d, index) => {
                        return (

                            <Grid2 xs={6}>
                                <Typography>
                                    <Switch checked={d.value}
                                            onChange={() => changeAdvancesSearchFieldsByNameVal(d.name)}/>
                                    {d.name}
                                </Typography>
                            </Grid2>

                        )
                    })
                }
            </Grid2>

        </>
    )

    const DataModelsSelection = () => (
        <>
            <Grid2 container sx={{maxWidth: 300}}>
                <Grid2 xs={12}>
                    <Typography>
                        <p><b>Data Models</b></p>
                    </Typography>
                </Grid2>

                {
                    advancedSearchFields.map((d, index) => {
                        return (

                            <Grid2 xs={6}>
                                <Typography>
                                    <Switch checked={d.value}
                                            onChange={() => changeAdvancesSearchFieldsByNameVal(d.name)}/>
                                    {d.name}
                                </Typography>
                            </Grid2>

                        )
                    })
                }
            </Grid2>

        </>
    )


    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                <Tooltip title="Search settings">
                    <IconButton type="button" sx={{p: '10px'}} aria-label="managed-search" onClick={handleClick}>
                        <ManageSearchIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            >
                <MenuItem>
                    <AssistedSearch/>
                </MenuItem>
                <Divider/>
                <MenuItem>
                    <AdvancedSearch/>
                </MenuItem>
            </Menu>
        </>
    );
}
