// the data for each filter-sets left nav facets is calculated here by the current active id.
import {atom, atomFamily, selector} from "recoil";
import FACET from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/facet-extraction-utility";
import {
    activeFilterSetIdAtom, activePoisWithDataSelectorFamily, applyFilter,
    facetFiltersAtomFamily
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";
import { searchDataAtom } from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/search";
import {genericSort, recursiveDeepClone} from "../../../../../../utility/utility";
import {sliderValuesAtomFamily} from "../YearSlider/YearSliderState";
import {topicsAtom} from "../../../../../../dataProvider/trend-analytics/general/topics/topics";

export const leftNavigationFacetsData = selector({
    key: "leftNavigationFacetsData-Key",
    get: async ({get}) => {
        // get the raw data from search. We have to aggregate independently
        const rawData = get(searchDataAtom);
        const activeFilterSetId = get(activeFilterSetIdAtom);
        // we iterate only over the current active filterSet.
        const facetFilters = get(facetFiltersAtomFamily(activeFilterSetId));
        const poiFilters = get(activePoisWithDataSelectorFamily(activeFilterSetId));
        const sliderValues = get(sliderValuesAtomFamily(activeFilterSetId));
        const filteredData = applyFilter(rawData, facetFilters, poiFilters, sliderValues);
        const topics = get(topicsAtom);
        return FACET.extractAllFacets(filteredData, topics);
    }
});


export const createLeftNavigationFacets = selector({
    key: "createLeftNavigationFacetsKey",
    get: async ({get}) => {
        // the content of the left nav facets is always dependent on the filterSet that is active.
        const activeFilterSetId = get(activeFilterSetIdAtom);
        // state of display all not only 3 preview.
        const previewState = get(previewAtom);
        // the already toggled ones. They show the first three elements
        const defaultOpened = get(defaultOpenStateAtom);
        // we store for each active filterset different open states. :)
        const facetSortingState = get(facetSortingStateAtomFamily(activeFilterSetId))
        const searchResults = get(leftNavigationFacetsData);
        // bring the data in the correct form.
        if (searchResults !== undefined) {
            let currentFacetKeys = Object.keys(searchResults)
            const previews = currentFacetKeys.map((d, i) => {
                const sortingStateForFacet = facetSortingState.find((e) => e.facet === d.toString());
                // we create an exception for the Years facet.


                return {
                    facet: d,
                    // all facets that are stored in default opened are set.
                    initialOpen: defaultOpened.find((e) => e === d) !== undefined,
                    data: {
                        // previewElements: searchResults[d.toString()].slice(0, 3),
                        // @ts-ignore
                        allElements: searchResults[d.toString()]
                    },
                    sorting: {
                        // we take the initial open state from another atomFamily. That gives opportunity to later on change it for each independent.
                        // method: sortingState.method,    // d !== 'Years' ? 'amount' : 'az',
                        // order is defaulted to descendant
                        // order: sortingState.order,// 'desc'
                        // @ts-ignore
                        azSorting: sortingStateForFacet.azSorting,
                        // @ts-ignore
                        amountSorting: sortingStateForFacet.amountSorting,
                        // @ts-ignore
                        activeSortingMethod: sortingStateForFacet.activeSortingMethod
                    }
                }
            });

            /** Preview State **/

            const clone = recursiveDeepClone(previews);
            // initial filling...
            if (previewState.length <= 0) {
                clone.map((d: { onlyPreview: boolean; }) => {
                    d.onlyPreview = true;
                });
            } else {
                // change if we already have values.
                clone.map((d: { facet: any; onlyPreview: any; }) => {
                    previewState.forEach((e) => {
                        // @ts-ignore
                        if (d.facet === e.facet) {
                            // @ts-ignore
                            d.onlyPreview = e.onlyPreview;
                        }
                    })
                    return d;
                });
            }


            // sort the data the right way.
            clone.map((d: { sorting: { activeSortingMethod: string; azSorting: any; amountSorting: any; }; data: { allElements: any[]; }; }) => {
                // const method = d.sorting.method === 'az' ? 'name' : 'amount';
                const sortingData = d.sorting.activeSortingMethod === 'az' ? d.sorting.azSorting : d.sorting.amountSorting;
                const method = sortingData.method === 'az' ? 'name' : 'amount';

                return d.data.allElements.sort(genericSort(method, sortingData.order));
            })

            return clone;
        }
    }
});

// all Facets that are listed here are open, others are closed on default
export const defaultOpenStateAtom = atom({
    key: "defaultOpenStateAtom-Key",
    default: [FACET.TOPIC, FACET.AUTHOR, FACET.COUNTRY],// [FACET.YEAR, FACET.TOPIC, FACET.AUTHOR, FACET.COUNTRY, FACET.AFFILIATION],
});


export const facetSortingStateAtomFamily = atomFamily({
    key: "facetSortingStateAtomFamily-Key",
    default: (id) => [
        {
            facet: FACET.YEAR,
            azSorting: {method: 'az', order: 'desc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'az'
        },
        {
            facet: FACET.TOPIC,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.COUNTRY,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.AFFILIATION,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.AUTHOR,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.TYPE,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.VENUE,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
        {
            facet: FACET.PREFIX,
            azSorting: {method: 'az', order: 'asc'},
            amountSorting: {method: 'amount', order: 'desc'},
            activeSortingMethod: 'amount'
        },
    ],
});

export const previewAtom = atom({
    key: "previewKey",
    default: [],
});

export const leftNavigationFacetsAtom = atom({
    key: "leftNavigationFacets-Key",
    default: createLeftNavigationFacets,
});