import React, {useEffect} from 'react';
import {
    Routes,
    Route,
    BrowserRouter as Router
} from "react-router-dom";
import PrivateRoute from "../../dataProvider/authentication/PrivateRoute/PrivateRoute";
import MetaNavigation from "../UI/GlobalNavigations/MetaNavigation/MetaNavigation";
import {useRecoilValue} from "recoil";
import {currentThemeSelector} from "../../styles/themes/themes";
import {ThemeProvider} from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import * as routes from './routes';
import LoadingSpinner from "../UI/LoadingSpinner/LoadingSpinner";

function Routing() {
    const currTheme = useRecoilValue(currentThemeSelector);
    const routeConfig: any = routes;

    /** we define everything here to make jsx more easy to read... */
    // routes
    // const defaultLocation: string = routes.default.location;
    // const homeLocation: string = routes.home.location;
    // const dataAnalyticsLocation: string = routes.dataAnalytics.location
    // const trendMonitoringLocation: string = routes.dataAnalytics.subRoutes.trendMonitoring.location;
    // const trendDetectionLocation: string = routes.dataAnalytics.subRoutes.trendDetection.location;
    // const literatureSearchLocation: string = routes.literatureSearch.location;
    // const simpleLiteratureSearchLocation: string = routes.literatureSearch.subRoutes.simpleLiteratureSearch.location;
    // const simpleLiteratureSearchDashboardLocation: string = routes.literatureSearch.subRoutes.simpleLiteratureSearch.subRoutes.simpleLiteratureSearchDashboard.location;
    // const guidedPrismaLocation: string = routes.literatureSearch.subRoutes.guidedPrisma.location;
    // const settingsLocation: string = routes.settings.location;
    // const corporateStrategyLocation: string = routes.corporateStrategy.location;
    // const guidedCorporateStrategyLocation: string = routes.corporateStrategy.subRoutes.guidedCorporateStrategy.location;
//
    // // roles
    // // const defaultRoles: string = routes.default.location;
    // // const homeAllowedRoles: string = routes.home.location;
    // const dataAnalyticsAllowedRoles: Array<string> = routes.dataAnalytics.roles_allowed
    // const trendMonitoringAllowedRoles: Array<string> = routes.dataAnalytics.subRoutes.trendMonitoring.roles_allowed;
    // const trendDetectionAllowedRoles: Array<string> = routes.dataAnalytics.subRoutes.trendDetection.roles_allowed;
    // const literatureSearchAllowedRoles: Array<string> = routes.literatureSearch.roles_allowed;
    // const simpleLiteratureSearchAllowedRoles: Array<string> = routes.literatureSearch.subRoutes.simpleLiteratureSearch.roles_allowed;
    // const guidedPrismaAllowedRoles: Array<string> = routes.literatureSearch.subRoutes.guidedPrisma.roles_allowed;
    // const settingsAllowedRoles: Array<string> = routes.settings.roles_allowed;
    // const simpleLiteratureSearchDashboardAllowedRoles: Array<string> = routes.literatureSearch.subRoutes.simpleLiteratureSearch.subRoutes.simpleLiteratureSearchDashboard.roles_allowed;
    // const corporateStrategyAllowedRoles: Array<string> = routes.corporateStrategy.roles_allowed;
    // const guidedCorporateStrategyAllowedRoles: Array<string> = routes.corporateStrategy.subRoutes.guidedCorporateStrategy.roles_allowed;
    /*
        const mapSubRoutes = (data: any) => {
             const componentObj = {};


            }


        const mapRoutes = (routes: any) => {

            return routes.map((route: any, index: number) => {
                if (route.subRoutes) {
                    // If the route has nested routes, recursively call the `mapRoutes` function
                    return mapRoutes(route.subRoutes);
                } else { // return(<Route index={false} path={d.location} element={d.component} key={i}/>)
                    // If the route doesn't have nested routes, return a `Route` component
                    return ( // <Route path={homeLocation} element={<LandingPage/>}/>
                        <Route
                            key={index}
                            path={route.location}
                            // index={route.subRoutes}
                            element={route.component}
                        />
                    );
                }
            });
        }; */
    /*
        <Route path={dataAnalyticsLocation}>

                            <Route index={true} element={<PrivateRoute rolesAllowed={dataAnalyticsAllowedRoles} children={<DataAnalytics/>}></PrivateRoute>}/>

                            <Route index={false} path={trendMonitoringLocation}
                                   element={<PrivateRoute rolesAllowed={trendMonitoringAllowedRoles} children={<TrendMonitoring/>}></PrivateRoute>}/>

                            <Route index={false} path={trendDetectionLocation}
                                   element={<PrivateRoute rolesAllowed={trendDetectionAllowedRoles} children={<TrendDetection/>}></PrivateRoute>}/>
         </Route>
     */

    // recursive algorithm that maps config of routes to react components. Checks for children etc.
    function buildRoutes(routes: any) {
        return routes.map((route: any, i: number) => {
            const childRoutes = route.subRoutes ? buildRoutes(route.subRoutes) : null;
            //  if(rolesAllowed.length === 0 || (rolesAllowed.length === 1 && rolesAllowed[0] === "limited_access_user")){
            //     return true;
            // }
            const noAuthNeeded = (route.roles_allowed.length === 0 || (route.roles_allowed.length === 1 && route.roles_allowed[0] === "limited_access_user"))

            return (
                route.subRoutes ?
                <Route key={route.name} path={route.location}>
                    {/* @ts-ignore */}
                    <Route index={true}  element={ noAuthNeeded ? route.component : <PrivateRoute rolesAllowed={route.roles_allowed} childs={route.lazyLoaded ?
                        <React.Suspense
                            fallback={<LoadingSpinner/>
                            }>
                            {route.component}
                        </React.Suspense> : route.component}> </PrivateRoute>} />
                    {childRoutes}
                </Route>
                    :
                    <Route key={route.name} path={route.location}
                        // @ts-ignore
                           element={ noAuthNeeded ? route.component  : <PrivateRoute rolesAllowed={route.roles_allowed} childs={route.lazyLoaded ?
                               <React.Suspense
                                   fallback={<LoadingSpinner/>
                                   }>
                                   {route.component}
                               </React.Suspense> : route.component}> </PrivateRoute>}>
                        {childRoutes}
                    </Route>

            );

        });
    }


    /*
                               // @ts-ignore
                               routeConfig.default.map((d, i) => {
                                   const hasSubroutes = d.subRoutes !== undefined;
                                   console.log(d);
                                   // hasSubroutes ?
                                   if(!hasSubroutes){
                                       return(<Route index={false} path={d.location} element={d.component} key={i}/>)
                                   } else {
                                       mapSubRoutes(d);
                                   }
                               })
       /*
          <Route path={corporateStrategyLocation}>
                               <Route index={true} element={<PrivateRoute rolesAllowed={corporateStrategyAllowedRoles} children={<CorporateStrategy/>}></PrivateRoute>} />

                               <Route index={false} path={guidedCorporateStrategyLocation}
                                      element={<PrivateRoute rolesAllowed={guidedCorporateStrategyAllowedRoles} children={<GuidedCorporateStrategy/>}></PrivateRoute>}/>
                           </Route>
        */


    // @ts-ignore
    return (
        <ThemeProvider theme={currTheme}>
            <CssBaseline/>
            <Router>
                <MetaNavigation/>
                <Routes>
                    {buildRoutes(routes.default)}
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default Routing;


/*
  <Route path={defaultLocation} element={<LandingPage/>}/>


<Route path={homeLocation} element={<LandingPage/>}/>

                        //import BusinessIcon from '@mui/icons-material/Business';


                        <Route path={corporateStrategyLocation}>
                            <Route index={true} element={<PrivateRoute rolesAllowed={corporateStrategyAllowedRoles} children={<CorporateStrategy/>}></PrivateRoute>} />

                            <Route index={false} path={guidedCorporateStrategyLocation}
                                   element={<PrivateRoute rolesAllowed={guidedCorporateStrategyAllowedRoles} children={<GuidedCorporateStrategy/>}></PrivateRoute>}/>
                        </Route>

                        <Route path={dataAnalyticsLocation}>

                            <Route index={true} element={<PrivateRoute rolesAllowed={dataAnalyticsAllowedRoles} children={<DataAnalytics/>}></PrivateRoute>}/>

                            <Route index={false} path={trendMonitoringLocation}
                                   element={<PrivateRoute rolesAllowed={trendMonitoringAllowedRoles} children={<TrendMonitoring/>}></PrivateRoute>}/>

                            <Route index={false} path={trendDetectionLocation}
                                   element={<PrivateRoute rolesAllowed={trendDetectionAllowedRoles} children={<TrendDetection/>}></PrivateRoute>}/>
                        </Route>
                        <Route path={literatureSearchLocation}>
                            <Route index={true} element={<PrivateRoute rolesAllowed={literatureSearchAllowedRoles} children={<LiteratureSearch/>}></PrivateRoute>}/>


                            <Route path={simpleLiteratureSearchLocation}>
                                <Route index={true}
                                       element={<PrivateRoute rolesAllowed={simpleLiteratureSearchAllowedRoles} children={<SimpleLiteratureSearchStart/>}></PrivateRoute>}/>

                                <Route index={false} path={simpleLiteratureSearchDashboardLocation}
                                       element={<PrivateRoute rolesAllowed={simpleLiteratureSearchDashboardAllowedRoles} children={<LiteratureSearchDashboard/>}></PrivateRoute>}/>

                            </Route>

                            <Route index={false} path={guidedPrismaLocation}
                                   element={<PrivateRoute rolesAllowed={guidedPrismaAllowedRoles} children={<GuidedPrisma/>}></PrivateRoute>}/>
                        </Route>
                        <Route path={settingsLocation} element={
                            <PrivateRoute rolesAllowed={settingsAllowedRoles} children={<Settings/>}></PrivateRoute>
                        }/>

*/