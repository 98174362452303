import Container from "@mui/material/Container";
import * as React from "react";
import Divider from "@mui/material/Divider";
import LiteratureSearchBar from "./LiteratureSearchBar/LiteratureSearchBar";

function SimpleLiteratureSearchStart() {
    return (
        <Container maxWidth="xl">
            <h1>Literature Search</h1>
            <Divider/>
            <LiteratureSearchBar marginTop={30}/>
        </Container>
    )
}

export default SimpleLiteratureSearchStart