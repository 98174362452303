import React from "react";
import DblpIcon from "../../../components/UI/icons/dbs/dblp";
import SpringerIcon from "../../../components/UI/icons/dbs/springer";
import EgIcon from "../../../components/UI/icons/dbs/eg";
import CrossrefIcon from "../../../components/UI/icons/dbs/crossref";
import PubbizIcon from "../../../components/UI/icons/dbs/pubbiz";
import TestIcon from "../../../components/UI/icons/dbs/Test";
import EgTestIcon from "../../../components/UI/icons/dbs/egTest";

export const initialDb = {
    name: 'springer',
    displayName: 'Springer',
    icon: <SpringerIcon/>,
}


export const dbConfig = [
    {
        name: 'springer',
        displayName: 'Springer',
        icon: <SpringerIcon/>,
    },
    {
        name: 'dblp',
        displayName: 'DBLP',
        icon: <DblpIcon/>,
    },
    /*
       {
           name: 'eg',
           displayName: 'EG',
           icon: <EgIcon/>,
       },
       {
           name: 'crossref',
           displayName: 'CrossRef',
           icon: <CrossrefIcon/>,
       },
       {
           name: 'pubbiz',
           displayName: 'Pubbiz',
           icon: <PubbizIcon/>,
       },
       {
           name: 'Test',
           displayName: 'Test',
           icon: <TestIcon/>,
       },
       {
           name: 'egTest',
           displayName: 'egTest',
           icon: <EgTestIcon/>,
       } */
    /*
    {
        name: 'lld',
        displayName: 'lld',
        icon: <EgTestIcon/>,
    },
     */
]

// const dbsToUse = ['dblp, eg, springer'];

// console.log(dbConfig);


export const vissightsConfig = {
    dbConfig: dbConfig,
    // as the API delivers all kind of not working rubbish we filter that stuff out.
    // by blacklisting not working and work in progress stuff we do not need to apply changes when new working databases are created.
    searchUrlPrefix: 'https://api.vissights.net/semaservice-web-api-vissights/v1/',
    baseQuery: "https://api.morpheus.fbmd.h-da.de/",
    authBaseQuery: "https://auth.morpheus.fbmd.h-da.de/",
    apiVersion: "v1",
    dbName: "springer",
    querySuffix: '&q=',
    dbSuffix: '?db=',
    themeOptions: ['light', 'dark'],
    defaultTheme: 'light',
    // devUrl: 'http://localhost:4000/graphql',
    // productionUrl: 'https://sina.media.h-da.de/graphql'
    // for v2 of api which is not working atm.
    // searchUrlPrefix: 'https://api.vissights.net/semaservice-web-api-vissights/v2/',
    // searchUrlAddendum: '/publications/search?q='}
}
