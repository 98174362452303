import Container from "@mui/material/Container";
import * as React from "react";
import Divider from "@mui/material/Divider";

function SearchTwo() {
    return (
        <Container maxWidth="xl">
            <h1>Literature Search 2.0</h1>
            <Divider/>
            Hallo Frau Clara, hier können Sie sich austoben :)


        </Container>
    )
}

export default SearchTwo