import {atom, atomFamily, selectorFamily, waitForNone} from "recoil";
import {recursiveDeepClone} from "../../../../../utility/utility";
import {poiDataSelectorFamily} from "../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";

export const displayLeftNavigationAtom = atom({
    key: "displayLeftNavigationAtom-Key",
    default: true
});

export const leftNavDrawerWidthAtom = atom({
    key: "leftNavDrawerWidthAtom-Key",
    default: 240
});


export const poiAtomFamily = atomFamily({
    key: "poiAtomFamily-Key",
    default: [],
});

// we filter for only active. We need this for filtersets and for updating inside PointsOfInterest.ts.
export const activePoiSelectorFamily = selectorFamily({
    key: "activePoiSelectorFamily-Key",
    get: (id) => ({get}) => {
        const pois = get(poiAtomFamily(id));
        return pois.filter((d) => d.active === true);
    },
});


export const poiExternalDataSelectorFamily = selectorFamily({
    key: "poiExternalDataSelectorFamily-Key",
    get: (id) => ({get}) => {
        const rawPois = get(poiAtomFamily(id));
        const clone = recursiveDeepClone(rawPois)
        const newData = get(waitForNone(clone.map(d => d.data = poiDataSelectorFamily(d.name))));
        return newData;
    }
});


export const poiWithDataSelectorFamily = selectorFamily({
    key: "poiWithDataSelector-Key",
    get: (id) => async ({get}) => {
        const rawPois = get(poiAtomFamily(id));
        const clone = recursiveDeepClone(rawPois)
        if (rawPois.length > 0) {
            const newData = get(poiExternalDataSelectorFamily(id)); // await get(waitForAll(clone.map(d => d.data = poiDataSelectorFamily(d.name))));
            // const newData = await Promise.all(clone.map((d) => poiDataSelectorFamily(d.name)));
            if (newData[newData.length - 1].state === 'loading') {
                clone.map((d) => {
                    return d.data = [];
                });
            } else {
                clone.map((d, i) => {
                    return d.data = newData[i].contents;
                });
            }
            // console.log(newData);
        }
        return clone;
    }
});
