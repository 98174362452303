import * as React from "react";
import {useRecoilState} from "recoil";
import {v4 as uuid4} from 'uuid';
import {snackBarAtom} from "../../../../../UI/CustomSnackBar/CustomSnackBar";
import LoadingSpinner from "../../../../../UI/LoadingSpinner/LoadingSpinner";
import WordCloudContainer from "../../../../../VisualizationModules/Visualizations/Wordcloud/WordcloudContainer";


function TrendMonitoring() {
    const [snackBarState, setSnackBarState] = useRecoilState(snackBarAtom);
    const newUUID = uuid4();
    // const containerDimensions = useRecoilValue(singleVisualizationSize);

   //  useEffect(() => {
   //      width = window.innerWidth - 50;
   //      height = window.innerHeight - 100;
   //  },[window.innerWidth, window.innerHeight])



    const handleClick = () => {
        setSnackBarState((prevState: any) => ({
            ...prevState,
            open: true,
        }));
    };


    // <Button onClick={(() => handleClick())}>Click me</Button>
    /*
    <Grid container sx={{alignItems: "center", justify: "center"}} spacing={{xs: 2, md: 3}}>
    </Grid>
     */

    return (
        <div>
            <React.Suspense
                fallback={<LoadingSpinner/>
                }>
                <WordCloudContainer id={newUUID} topControls={{}} deleteContainer={{}}></WordCloudContainer>
            </React.Suspense>
        </div>
    )
}

export default TrendMonitoring