import {atom, atomFamily, selectorFamily} from "recoil";
import {activeDatasetAtomFamily} from "../../global-visualization-state";
import {fetchAggregatedMakroLevelDataSelectorFamily} from "../../../../../dataProvider/trend-analytics/trend/trend";
import {
    fetchSearchHistorySelectorFamily
} from "../../../../../dataProvider/trend-analytics/searchHistory/searchHistory";
import {monitoringDataAtom} from "../../../../../dataProvider/userDataModel/UserDataModel";


export const chartDataWordCloudSelectorFamily = selectorFamily({
    key: 'chartDataWordCloudSelectorFamilyKey',
    get: ({db: db , dataset: dataset}) =>  ({get}) => {
        const searchHistory =  get(fetchSearchHistorySelectorFamily(db));
        const overviewData =  get(fetchAggregatedMakroLevelDataSelectorFamily(db)).slice(0, 12);
        const monitoringData =  get(monitoringDataAtom);
        console.log(overviewData)

        console.log()

        if (dataset === 'searchHistory') {
            return searchHistory;
        } else if (dataset === 'topicOverview') {
            return overviewData;
        } else if (dataset === 'monitoring') {
            return monitoringData;
        } else {
            return searchHistory;
        }
    },
});

export const wordCloudChartDataAtomFamily = atomFamily({
    key: "wordCloudChartDataAtomFamilyKey",
    default: selectorFamily({
        key: 'wordCloudChartDataAtomFamily/Default',
        get: id => ({get}) => {
            const dataset = get(activeDatasetAtomFamily(id));
            console.log(dataset)
            return chartDataWordCloudSelectorFamily({db: "springer", dataset: dataset.toString()});
        },
    }),
});


export const displayCreateNewMonitoringItemModalAtom = atom({
    key: "displayCreateNewMonitoringItemModalAtom-Key",
    default: false,
});



