import {atom, atomFamily, selectorFamily} from "recoil";
import {facetFiltersAtomFamily} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/filters/filters";
import {
    preProcessedRawDataSelector
} from "../../../../../../dataProvider/trend-analytics/zoomAndFilter/search/facetation/facetation";
import {leftNavigationFacetsData} from "../Facets/Facets-State";

export const yearSliderValuesAtom = atom({
    key: "yearSliderValuesAtom-Key",
    default: [1900, 2023]
});


// goes to true when user manually changes slider.
export const manualSliderControlAtomFamily = atomFamily({
    key: "manualSliderControlAtomFamily-Key",
    default: (id) => false
});


export const sliderMinMaxValuesSelectorFamily = selectorFamily({
    key: "sliderMinMaxValuesSelectorKey",
    get: (id) => async ({get}) => {
        const response = await get(preProcessedRawDataSelector);
        const len = response['Years'].length;
        let values;
        if (len === 0) {
            values = [2020, 2020]
        } else if (len === 1) {
            values = [Number(response['Years'][0].name), Number(response['Years'][0].name)]
        } else if (len > 1) {
            // first and last element refer to min and max year.
            values = [Number(response['Years'][len - 1].name), Number(response['Years'][0].name)]
        }
        // first and last element refer to min and max year.
        return values;
        // }
    }
});


export const sliderValuesSelectorFamily = selectorFamily({
    key: "sliderValuesSelectorFamily-Key",
    get: (id) => async ({get}) => {
        const response = get(leftNavigationFacetsData);
        const len = response['Years'].length;
        let values;
        if (len === 0) {
            values = [2020, 2020]
        } else if (len === 1) {
            values = [Number(response['Years'][0].name), Number(response['Years'][0].name)]
        } else if (len > 1) {
            // first and last element refer to min and max year.
            values = [Number(response['Years'][len - 1].name), Number(response['Years'][0].name)]
        }
        return values;
    },
});


// refers to min max of whole dataset loaded
export const sliderMinMaxValuesAtomFamily = atomFamily({
    key: "sliderMinMaxValuesKey",
    default: (id) => sliderMinMaxValuesSelectorFamily(id)
});

// refers to current filtered data min max initially, then it changes by using the filters.
export const sliderValuesAtomFamily = atomFamily({
    key: "sliderValuesAtomFamily-Key",
    default: (id) => sliderMinMaxValuesSelectorFamily(id) // sliderMinMaxValuesSelectorFamily(id)// sliderValuesSelectorFamily(id),
});

export const isSliderActiveSelectorFamily = selectorFamily({
    key: "isSliderActiveKey",
    get: (id) => ({get}) => {
        const minMax = get(sliderMinMaxValuesAtomFamily(id));
        const sliderVals = get(sliderValuesAtomFamily(id));
        const facetFilters = get(facetFiltersAtomFamily(id));
        const facetFilterSet = !facetFilters.find((d) => d.facet === 'Years');
        const sliderMoved = (minMax[0] !== sliderVals[0]) || (minMax[1] !== sliderVals[1]);
        // if there are differences the slider is active.
        return facetFilterSet && sliderMoved
    }
});