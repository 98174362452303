import LandingPage from "../Modules/LandingPage/LadingPage";
import Settings from "../Modules/Settings/Settings";
import Profile from "../UI/icons/profile/Profile";
import CorporateStrategy from "../Modules/CorporateStrategy/CorporateStrategy";
import GuidedCorporateStrategy from "../Modules/CorporateStrategy/GuidedCorporateStrategy/GuidedCorporateStrategy";
import LiteratureSearch from "../Modules/LiteratureSearch/LiteratureSearch";
import SimpleLiteratureSearchStart
    from "../Modules/LiteratureSearch/SimpleLiteratureSearch/SimpleLiteratureSearchStart";
import LiteratureSearchDashboard
    from "../Modules/LiteratureSearch/SimpleLiteratureSearch/LiteratureSearchDashboard/LiteratureSearchDashboard";
import GuidedPrisma from "../Modules/LiteratureSearch/GuidedPrisma/GuidedPrisma";
import Apis from "../Modules/Apis/Apis";
import TrendAnalyticsApi from "../Modules/Apis/TrendAnalyticsApi/TrendAnalyticsApi";
import SustainabilityApi from "../Modules/Apis/SustainabilityApi/SustainabilityApi";
import SearchTwo from "../Modules/LiteratureSearch/SearchTwo/SearchTwo";
import CorporateForesight from "../Modules/CorporateStrategy/CorporateForesight/CorporateForesight";
import TrendMonitoring
    from "../Modules/CorporateStrategy/CorporateForesight/DataAnalytics/TrendMonitoring/TrendMonitoring";
import TrendDetection
    from "../Modules/CorporateStrategy/CorporateForesight/DataAnalytics/TrendDetection/TrendDetection";
import TrendDashboard
    from "../Modules/CorporateStrategy/CorporateForesight/DataAnalytics/TrendDashboard/TrendDashboard";


const routeConfig = [
    {
        name: 'home',
        location: '/',
        component: <LandingPage/>,
        // empty refers to all roles allowed
        roles_allowed: ['full_access_user']
    },
    {
        name: 'home',
        location: 'home',
        component: <LandingPage/>,
        roles_allowed: []
    },
    // overview: {name: 'overview', location: '/overview/'},
    // zoomAndFilter: {name: 'zoomAndFilter', location: '/zoomAndFilter/'},
    // reporting: {name: 'reporting', location: '/reporting/'},
    {
        name: 'admin',
        location: 'admin',
        component: <Settings/>,
        roles_allowed: ['admin']
    },
    {
        name: 'settings',
        location: 'settings',
        component: <Settings/>,
        roles_allowed: ['full_access_user']
    },
    {
        name: 'profile',
        location: 'user',
        component: <LandingPage/>,
        roles_allowed: ['full_access_user']
    },
    {
        name: 'apis',
        location: 'apis',
        component: <Apis/>,
        roles_allowed: [],
        subRoutes: [{
            name: 'trend-analytics-api',
            component: <TrendAnalyticsApi/>,
            location: 'trend-analytics-api',
            roles_allowed: [],
        }, {
            name: 'sustainability-api',
            component: <SustainabilityApi/>,
            location: 'sustainability-api',
            roles_allowed: [],
        }]
    },
    {
        name: 'projects',
        location: 'projects',
        // component: <Projects/>,
        roles_allowed: ['full_access_user']
    },
    {
        name: 'corporate-strategy',
        location: 'corporate-strategy',
        roles_allowed: ['full_access_user'],
        component: <CorporateStrategy/>,
        subRoutes: [{
            name: 'guided-corporate-strategy',
            component: <GuidedCorporateStrategy/>,
            location: 'guided-corporate-strategy',
            roles_allowed: ['full_access_user'],
        }, {
            name: 'corporate-foresight',
            component: <CorporateForesight/>,
            location: 'corporate-foresight',
            roles_allowed: ['full_access_user'],
            subRoutes: [
                {
                    name: 'trend-monitoring',
                    component: <TrendMonitoring/>,
                    location: 'trend-monitoring',
                    lazyLoaded: true,
                    roles_allowed: ['full_access_user'],
                },
                {
                    name: 'trend-detection',
                    component: <TrendDetection/>,
                    location: 'trend-detection',
                    lazyLoaded: true,
                    roles_allowed: ['full_access_user'],
                },
                {
                    name: 'trend-dashboard',
                    component: <TrendDashboard/>,
                    location: 'trend-dashboard',
                    lazyLoaded: true,
                    roles_allowed: ['full_access_user'],
                }
            ]
        }]
    },
    /*{
        name: 'data-analytics',
        location: 'data-analytics',
        component: <DataAnalytics/>,
        roles_allowed: ['full_access_user'],
        subRoutes: [
            {
                name: 'trend-monitoring',
                component: <TrendMonitoring/>,
                location: 'trend-monitoring',
                lazyLoaded: true,
                roles_allowed: ['full_access_user'],
            },
            {
                name: 'trend-detection',
                component: <TrendDetection/>,
                location: 'trend-detection',
                lazyLoaded: true,
                roles_allowed: ['full_access_user'],
            }
        ]
    },*/
    {
        name: 'literature-search',
        location: 'literature-search',
        component: <LiteratureSearch/>,
        roles_allowed: ['limited_access_user'],
        subRoutes: [
            {
                name: 'simple-literature-search',
                component: <SimpleLiteratureSearchStart/>,
                location: 'simple-literature-search',
                roles_allowed: ['full_access_user'],
                subRoutes: [
                    {
                        name: 'simple-literature-search-dashboard',
                        component: <LiteratureSearchDashboard/>,
                        location: 'dashboard',
                        roles_allowed: ['full_access_user'],
                    }
                ]
            },
            {
                name: 'search-two',
                component: <SearchTwo/>,
                location: 'search-two',
                roles_allowed: [],
            },
            {
                name: 'guided-prisma',
                component: <GuidedPrisma/>,
                location: 'guided-prisma',
                roles_allowed: ['full_access_user'],
            }
        ]
    }
]

export default routeConfig;