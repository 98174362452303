import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import {useRecoilState, useRecoilValue} from "recoil";
import {displayLeftNavigationAtom, leftNavDrawerWidthAtom} from "./LeftNavigationState";
import YearSlider from "./YearSlider/YearSlider";
import Facets from "./Facets/Facets";
import PointsOfInterest from "./PointsOfInterest/PointsOfInterest";
import {useEffect} from "react";


export default function LeftNavigation(props: any) {
    const [open, setOpen] = useRecoilState(displayLeftNavigationAtom);
    const drawerWidth = useRecoilValue(leftNavDrawerWidthAtom);
    // const facetData = useRecoilValue(facet)

    // reset state of subcomponents on db or searchterm change.
    useEffect(()=> {

    },[])


    return (
        <Box sx={{display: 'flex'}}>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <List style={{marginTop: 145}}>
                    <PointsOfInterest/>
                    <div style={{marginLeft: 10}}>
                        <YearSlider/>
                    </div>
                    <div style={{marginTop: 80}}>
                        <Facets/>
                    </div>
                </List>
            </Drawer>
        </Box>
    );
}