
// here we preprocess the data
import {selector} from "recoil";
import FACET from "../../facet-extraction-utility";
import {searchDataSelector} from "../search";
import {topicsAtom} from "../../../general/topics/topics";

export const preProcessedRawDataSelector = selector({
    key: "preProcessedRawDataSelectorKey",
    get: ({get}) => {
        // const rawData =  get(searchDataSelector);
        // create facet data from the filtered data.
        return get(facetedRawDataSelector);
    }
});

export const amountRawDataSelector = selector({
    key: "amountRawDataSelectorKey",
    get: ({get}) => {
        const rawData = get(searchDataSelector);
        return rawData.length;
    }
});

export const facetedRawDataSelector = selector({
    key: "facetedRawDataSelectorKey",
    get: ({get}) => {
        const topics = get(topicsAtom);
        const rawSearchData = get(searchDataSelector);
        return FACET.extractAllFacets(rawSearchData, topics);
    }
});


