import {Paper, Grid, Box} from "@mui/material";
import {fetchAggregatedMakroLevelDataGroupedSelectorFamily} from "../../../dataProvider/trend-analytics/trend/trend";
import {useRecoilValue} from "recoil";
import {useEffect, useRef, useState} from "react";
import SparklineContainer
    from "../../VisualizationModules/Visualizations/Sparkline/SparklineContainer/SparklineContainer";
import {ToggleButton} from "@mui/lab";
import Typography from "@mui/material/Typography";
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import {genericSort, recursiveDeepClone} from "../../../utility/utility";

function StaticDashboard() {
    const loadedData = useRecoilValue(fetchAggregatedMakroLevelDataGroupedSelectorFamily("springer"));
    const groupedData = recursiveDeepClone(loadedData)
    // console.log(groupedData);
    const [width, setWidth] = useState(null);
    const [height, setHeight] = useState(null);
    const containerRef = useRef(null);
    const [useNormalization, setUseNormalization] = useState(true);
    const [aggregation, setAggregation] = useState("trendNormalized");

    const dataToDisplay = (data) => {
        if (useNormalization) {
            return data?.forecastNormalized?.data?.timeseries;
        } else {
            return data?.forecast?.data?.timeseries;
        }
    };

    useEffect(() => {
        const handleResize = () => {
            // @ts-ignore
            setWidth(containerRef.current.getBoundingClientRect().width);
            setHeight(containerRef.current.getBoundingClientRect().height);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    /*
    <ToggleButton text={"normalization"} buttonState={useNormalization}
                             setButtonState={setUseNormalization}></ToggleButton>
                <ToggleButton text={"forecast"} buttonState={useForecast} setButtonState={setUseForecast}></ToggleButton>
     */
    return (
        <Box sx={{flexGrow: 1}}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} md={2}></Grid>
                <Grid item xs={6} sm={3} md={2}>
                    <ToggleButton
                        value="normalization"
                        selected={useNormalization}
                        onChange={() => {
                            setUseNormalization(!useNormalization);
                        }}
                    >
                        <MultilineChartIcon></MultilineChartIcon>
                    </ToggleButton>


                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={3} md={2}>
                    <Paper elevation={3} style={{height: "40vh"}}>
                        <div style={{textAlign: "center"}}>
                            <b>Your Watchlist</b>

                        </div>
                    </Paper>
                </Grid>

                {
                    groupedData.map((group, j) => {
                        group.sort(genericSort(aggregation, "desc"))

                        return (
                            <Grid item xs={2} key={group + '-' + j}>
                                <Paper elevation={3} style={{height: 100}} ref={containerRef}>
                                    <div style={{textAlign: "center"}}>
                                        <b>Group - {j}</b>
                                        {group.map((d, i) => {
                                            return (
                                                <SparklineContainer data={dataToDisplay(d)} name={d.name}
                                                                    useNormalization={useNormalization}
                                                                    width={1300}
                                                                    height={height}></SparklineContainer>
                                            )
                                        })
                                        }
                                    </div>
                                </Paper>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Box>
    )
}

export default StaticDashboard